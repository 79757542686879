import * as React from 'react';
import { Canvas } from '../canvas/Canvas';
import { ReinhardToneMapping } from 'three';
import { Main } from './Main';
import { Camera } from './Camera';
import { Lights } from './Lights';
import { UIContext } from '../../context/UIContext';
import { LoadingContext, RENDERING } from '../../context/LoadingContext';
import { isMobile } from '../../utils/is-mobile';

export const Scene = (props: any) => {
  const { selectedCategory, setSelectedCategory } = React.useContext(UIContext);
  const { loadingState } = React.useContext(LoadingContext);

  return (
    <Canvas
      invalidateFrameloop={isMobile && !!selectedCategory}
      shadowMap
      onCreated={({ gl, scene }) => {
        (gl as any).antialias = false;

        scene.background = null;
        gl.gammaOutput = true;
        gl.autoClear = false;
        gl.physicallyCorrectLights = false;
        gl.pixelRatio = window.devicePixelRatio;
        gl.setClearColor(0xffffff, 0.0);
        gl.gammaFactor = 1.5;
        gl.localClippingEnabled = true;
        gl.toneMapping = ReinhardToneMapping;
        gl.toneMappingExposure = 1.5;
      }}
      onPointerMissed={() => {
        if (selectedCategory) {
          setSelectedCategory(null);
        }
      }}
    >
      <Lights />
      <Camera zoom={props.zoom}>{loadingState >= RENDERING && <Main />}</Camera>
    </Canvas>
  );
};
