import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring'
import { useDrag } from 'react-use-gesture'

import { breakpoints, variables } from '../../styles/variables';
import { UIContext } from '../../context/UIContext';

import { CarouselButton } from './CarouselButton';

interface IProps {
  slideCount?: number;
  isLarge?: boolean;
  isConstrained?: boolean;
  children: React.ReactNode;
}

const Root = styled.div`
  display: block;
  position: relative;
  z-index: 0;
`;

const Inner = styled(animated.ol)`
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;

  transition: transform 450ms ease-in-out;
  will-change: transform;

  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Item = styled.li<{ isLarge: boolean, isConstrained: boolean }>`
  flex: none;
  display: flex;
  align-self: stretch;

  margin-right: 30px;

  width: 75%;
  min-width: 240px;
  max-width: ${props => (props.isLarge || props.isConstrained ? '670px' : '320px')};
  height: auto;
  user-select: none;

  @media (min-width: ${breakpoints.md}) {
    width: ${props => ((props.isConstrained) ? '95%' : 'calc(50% - 30px)')};
  }
`;

const ButtonWrap = styled.div`
  position: absolute;
  z-index: 1;
  top: -40px;
  right: -15px;

  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    top: -55px;
  }
`;

const ButtonPrev = styled.div`
  display: inline-flex;
  align-items: center;


  transform: rotate(-180deg);
`;

const ButtonNext = styled.div`
  display: inline-flex;
  align-items: center;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 10px;
  }
`;

const Overflow = styled.div`
  position: relative;

  &::before {
    content: '';

    position: absolute;
    bottom: -60px;
    left: 60px;
    right: 0;
    z-index: 0;
    opacity: 0.2;

    margin-top: 385px;

    width: 200vw;
    height: 160px;

    pointer-events: none;

    background: url(${require('../../assets/images/black-dot-opaque.svg')}) repeat;
    background-size: 10px;
  }
`;

export const Carousel = ({
  children,
  isLarge,
  isConstrained,
  slideCount,
}: IProps) => {
  const track = useRef<HTMLOListElement>(null);
  const carouselWrap = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const [canMove, setCanMove] = useState(true);
  const [canMoveForwards, setCanMoveForwards] = useState(true);
  const [position, setPosition] = useState(0);
  const [xPos, setXPos] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState('');
  const { isMobile } = useContext(UIContext);

  const V_THRESHOLD = 0.1
  const childCount = children.length;
  const canMoveBackwards = activeSlide > 0;
  const count =  isMobile ? childCount : Math.ceil(childCount / slideCount);


  const [carouselProps, setCarousel] = useSpring(() => ({
    x: 0,
    config: { mass: 0.6, friction: 16 },
  }));

  const bind = useDrag(({ last, direction: [xDir], vxvy: [vx, vy] }) => {
    if (last) {
      const dir = xDir < 0 ? 'left' : 'right'; // Direction should either point left or right

      if (vx < -V_THRESHOLD && dir === 'left') {
        onNext();
      }

      if (vx > V_THRESHOLD && dir === 'right') {
        onPrevious();
      }
    }
  });

  // get size of items (assumes even size)
  const getSize = () => {
    const itemCount = (isMobile || isConstrained) ? 1 : slideCount;
    const gutterSize = 30;

    
    const lastItem = (track.current!.lastChild as any).getBoundingClientRect();
    const trackLength = Math.ceil(track.current!.scrollWidth / (childCount / itemCount));
    const moveLength = (lastItem.width + gutterSize) * itemCount;

    return moveLength || 0;
  };

  // move track
  const move = () => {
    setPosition(activeSlide * getSize());
  };

  // transition end on track move to check if carousel can move
  useEffect(() => {
    if (!track.current) {
      return;
    }
    track.current.addEventListener('transitionend', onUpdate);

    return () => {
      if (!track.current) {
        return;
      }
      track.current.removeEventListener('transitionend', onUpdate);
    };
  
  }, [activeSlide]);


  // listen to activeSlide updates
  useEffect(() => {
    move();
  }, [activeSlide]);

  const onNext = () => {
    if (!canMove || !canMoveForwards) {
      return;
    }

    setActiveSlide(activeSlide + 1);
    setCanMove(false);
  };

  const onPrevious = () => {
    if (activeSlide === 0 || !canMove) {
      return;
    }

    setActiveSlide(activeSlide - 1);
    setCanMove(false);
  };

  // can carousel move?
  const onUpdate = () => {
    if (!track.current) {
      return;
    }

    const lastItem = (track.current.lastChild as any).getBoundingClientRect();
    const rectLeft = lastItem.x || lastItem.left;
    const innerWidth =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    setCanMoveForwards(innerWidth - (rectLeft + lastItem.width) <= 0);
    setCanMove(true);
  };

  return (
    <Root ref={carouselWrap}>
      <ButtonWrap>
        {slideCount < childCount && (
          <ButtonPrev>
            <CarouselButton
              onClick={onPrevious}
              disabled={!canMoveBackwards}
            />
          </ButtonPrev>
        )}
        {slideCount < childCount && (
          <ButtonNext>
            <CarouselButton
              onClick={onNext}
              disabled={!canMoveForwards}
            />
          </ButtonNext>
        )}
      </ButtonWrap>
      <Overflow {...bind()}>
        <Inner
          ref={track}
          // style={{ transform: `translateX(-${position}px)` }}
          style={{
            ...carouselProps,
            transform: carouselProps.x.interpolate((x) => `translate3d(-${position}px, 0, 0)`),
          }}
        >
          {children!.map((child, i) => (
            <Item key={`carousel__item-${i}`} isLarge={isLarge} isConstrained={isConstrained}>
              {child}
            </Item>
          ))}
        </Inner>
      </Overflow>

    </Root>
  );
};

Carousel.defaultProps = {
  slideCount: 2,
};
