import React, { createContext, useMemo } from 'react';
import { Data, Product } from '../types';
import uniqBy from 'lodash/uniqBy';
import flatMap from 'lodash/flatMap';
import fromPairs from 'lodash/fromPairs';

interface DataContextProps {
  data: Data;
  sectionBundles: { [key: string]: Product[] };
}

export const DataContext = createContext<DataContextProps>({} as any);

interface DataProviderProps {
  data: Data;
  children: React.ReactNode;
  sectionBundles: { [key: string]: Product[] };
}

export const DataProvider = ({ children, data }: Omit<DataProviderProps, 'sectionBundles'>) => {
  const sectionBundles = useMemo(
    () =>
      fromPairs(
        data.sections.map(s => [
          s.title,
          uniqBy(
            flatMap(data.categories.filter(x => x.section_id === s.id), x => x.products).filter(
              x => x.type === 'bundle'
            ),
            'id'
          ),
        ])
      ),
    [data]
  );

  return <DataContext.Provider value={{ data, sectionBundles }}>{children}</DataContext.Provider>;
};
