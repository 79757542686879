import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';
import { useSpring, useTransition, animated } from 'react-spring';

interface WrapperProps {
  visible?: boolean;
}

type ModalProps = WrapperProps & {
  children?: React.ReactNode;
  onClose?(): void;
};

const ModalWrapper = styled(animated.div)<{ visible: boolean }>`
  position: fixed;
  left: 100%;
  top: 0;
  bottom: 0;

  z-index: 5;

  width: 100vw;
  padding: 22.5px 25px 30px 55px;

  background: ${variables.colors.boneWhite};

  box-shadow: ${props => (props.visible ? '0 2px 200px 0 rgba(0, 0, 0, 0.2)' : 'none')};

  pointer-events: none;

  will-change: transform;

  overflow: hidden;
  overflow-y: scroll;

  @media (min-width: ${breakpoints.lg}) {
    width: 50vw;
    padding: 45px 50px 60px 50px;
  }

  @media (min-width: 1440px) {
    width: 50vw;
    padding: 45px 50px 60px 110px;
  }
`;

export const Modal = ({ visible, onClose, children }: ModalProps) => {
  const styles = useSpring({
    transform: visible ? 'translate3d(-100%, 0,0)' : 'translate3d(0%,0,0)',
    pointerEvents: visible ? 'auto' : 'none',
  });

  return (
    <>
      <ModalWrapper visible={visible!} style={styles}>
        {children}
      </ModalWrapper>
    </>
  );
};
