import React from 'react';
import styled from 'styled-components';
import { responsiveFont, getPunctuation, formatHeading } from '../../styles/utils';
import { variables } from '../../styles/variables';

interface HeadlineProps {
  text: string;
}

const Title = styled.h2<{ long: boolean }>`
  ${responsiveFont(36, 70)};
  position: relative;

  font-family: ${variables.font.familyHeading};
  font-weight: 700;
  line-height: ${74 / 70};
  position: relative;

  &::after {
    content: '';

    display: inline-block;

    position: relative;
    top: 1px;

    margin-left: ${props => (props.long ? '0.195em' : '0.08em')};
    margin-right: -0.275em;
    width: 0.18em;
    height: 0.18em;

    background-color: ${variables.colors.red};

    border-radius: 50%;
  }
`;

const Punctuation = styled.span`
  position: absolute;
`;

const Period = styled.span`
  display: inline-block;
  height: 0.675em;
  vertical-align: top;
  overflow: hidden;
`;

export const Headline = ({ text }: HeadlineProps) => {
  const punctuation = getPunctuation(text);

  return (
    <Title long={punctuation === '?'}>
      <span dangerouslySetInnerHTML={{__html: formatHeading(text) }}/>
      {punctuation &&
        <Punctuation>
          <Period>{punctuation}</Period>
        </Punctuation>
      }
    </Title>
  );
};
