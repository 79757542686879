import { Texture } from 'three';

export function copyTextureProps(mat1: Texture, mat2: Texture) {
  mat2.flipY = mat1.flipY;
  mat2.wrapS = mat1.wrapS;
  mat2.wrapT = mat1.wrapT;
  mat2.encoding = mat1.encoding;
  mat2.rotation = mat1.rotation;
  mat2.offset = mat1.offset;
  mat2.magFilter = mat1.magFilter;
  mat2.mapping = mat1.mapping;
  mat2.minFilter = mat1.minFilter;
}
