import * as React from 'react';
import Helmet from 'react-helmet';
import { Snjallheimilid } from '../components/snjallheimilid/Snjallheimilid';
import { Header } from '../components/header/Header';
import WifiSvg from '../assets/svg/wifi.svg';
import EntertainmentSvg from '../assets/svg/tv.svg';
import SecuritySvg from '../assets/svg/privacy.svg';
import LightingSvg from '../assets/svg/lighting.svg';
import HomeControlSvg from '../assets/svg/home-control.svg';
import BundlesSvg from '../assets/svg/bundles.svg';
import { Data, Product } from '../types';
import { DataProvider } from '../context/DataContext';
import { graphql } from 'gatsby';
import camelCase from 'lodash/camelCase';

const data: Data = {
  sections: [
    {
      id: 'internet',
      title: 'Netið',
      icon: <WifiSvg />,
    },
    {
      id: 'entertainment',
      title: 'Skemmtun',
      icon: <EntertainmentSvg />,
    },
    {
      id: 'security',
      title: 'Öryggi',
      icon: <SecuritySvg />,
    },
    {
      id: 'lighting',
      title: 'Lýsing',
      icon: <LightingSvg />,
    },
    {
      id: 'home-control',
      title: 'Heimilið',
      icon: <HomeControlSvg />,
    },
    {
      id: 'bundles',
      title: 'Pakkar',
      icon: <BundlesSvg />,
    },
  ],
  categories: [
    {
      section_id: 'security',
      id: 'security-cam',
      icon: 'icon_camera',
      title: 'Öryggis&shy;myndavél',
      description:
        'Þú slakar bara á uppi í sófa eða úti á landi í verðskulduðu fríi, snjalltækin passa heimilið og láta þig vita ef eitthvað grunsamlegt á sér stað.',
      instances: [
        {
          tooltip: 'above',
          position: [-0.6546, 1.6556, -0.2989],
        },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'security-cam-outdoor',
      icon: 'icon_camera',
      title: 'Úti öryggis&shy;myndavél',
      description:
        'Þú slakar bara á uppi í sófa eða úti á landi í verðskulduðu fríi, snjalltækin passa heimilið og láta þig vita ef eitthvað grunsamlegt á sér stað.',
      instances: [
        {
          position: [-0.7926, 0.794, 0.7296],
          rotation: [0.018531085978638066, -1.5707963267948966, 0],
          quaternion: [
            0.006551634520292282,
            -0.7070764303207397,
            -0.006551634520292282,
            0.7070764303207397,
          ],
        },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'doorbell',
      icon: 'icon_doorbell',
      title: 'Dyrabjalla',
      description:
        'Ding dong! Voru krakkarnir að gera dyraat eða ætlaði amma að koma með nýbakað? Þú getur aflæst heimilinu hvenær sem er, hvort sem þú ert í vinnunni eða á leiðinni heim.',
      instances: [
        {
          tooltip: 'above',
          position: [-0.8421, 0.3178, 0.7246],
        },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'lock',
      icon: 'icon_doorlock',
      title: 'Lás',
      description:
        'Ding dong! Voru krakkarnir að gera dyraat eða ætlaði amma að koma með nýbakað? Þú getur aflæst heimilinu hvenær sem er, hvort sem þú ert í vinnunni eða á leiðinni heim.',
      instances: [
        {
          position: [-0.9521, 0.2678, 0.7246],
          tooltip: 'left',
        },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'thermo',
      icon: 'icon_thermo',
      title: 'Loftgæði',
      description:
        'Fylgstu vel með loftgæðum og hita á heimilinu! Þú átt skilið að anda að þér besta lofti sem völ er á.',
      instances: [
        {
          position: [-0.8597, 1.2963, -0.5257],
          lookAt: {
            position: [-0.9097, 1.2963, 2.5],
            rotation: [0, -Math.PI / 16, 0],
          },
        },
        {
          tooltip: 'above',
          position: [-0.2072, 0.351, -0.5009],
          lookAt: {
            position: [-0.1072, 0.351, 2.5],
            rotation: [0, -Math.PI / 24, 0],
          },
        },
        {
          position: [1.3212, 0.3712, -0.4994],
          lookAt: {
            position: [1.1212, 0.3712, 2.5],
            rotation: [0, -Math.PI / 12, 0],
          },
        },
        {
          tooltip: 'above',
          position: [1.7377, 0.3798, -0.4969],
        },
        {
          tooltip: 'above',
          position: [1.7969, 1.2621, -0.0915],
          rotation: [0, -1.5705346837480791, 0],
          quaternion: [0, -0.7071067690849304, 0, 0.7071067690849304],
          lookAt: {
            rotation: [0, -Math.PI / 6, 0],
            position: [0.75, 1.2621, 2.5],
          },
        },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'smoke-detector',
      icon: 'icon_smoke-detector',
      title: 'Reykskynjari',
      description:
        'Reykskynjarinn veit upp á hár hvort þú hafir verið að brenna brauð í brauðristinni, eða hreinlega að kveikja í eldhúsinu.',
      instances: [
        {
          position: [-0.8514, 1.6407, -0.5275],
          lookAt: {
            position: [-1.0514, 1.6407, 2.5],
            rotation: [0, -Math.PI / 12, 0],
          },
        },
        { tooltip: 'above', position: [0.3968, 0.7571, -0.4994] },
        {
          tooltip: 'above',
          position: [2.9455, 0.7713, -0.4988],
          lookAt: {
            position: [2.7455, 0.7713, 2.5],
            rotation: [0, -Math.PI / 12, 0],
          },
        },
        { tooltip: 'above', position: [-2.5191, 0.7723, -0.5023] },
        { position: [-0.5784, 0.7723, -0.5007] },
        { tooltip: 'above', position: [0.391, 1.6102, -0.5245] },
      ],
      products: [],
    },
    {
      section_id: 'internet',
      id: 'router',
      title: 'Netbeinir',
      icon: 'icon_router',
      description:
        'Með neti byggði snjallur maður hús. Grunnurinn að snjöllu heimili er nettengingin þín. Við erum kannski ekki smiðir en við vitum hvað þarf til að byggja snjallt heimili.',
      instances: [
        {
          tooltip: 'above',
          position: [1.7206, 1.0337, -0.5192],
          lookAt: {
            position: [1.8606, 1.0337, 3.5],
            rotation: [0, -Math.PI / 24, 0],
          },
        },
      ],
      products: [],
    },
    {
      section_id: 'internet',
      id: 'antenna',
      icon: 'icon_extender',
      title: '4.5G Loftnet',
      description:
        'Er netið að stríða þér? Á stöðum þar sem minna netsamband er í boði færð þú forskot á alla hina með loftbelginn á toppi hússins og hann nælir í meira net fyrir þig.',
      instances: [
        {
          tooltip: 'left',
          position: [0, 0, 0],
          lookAt: {
            position: [3.3, 2.6797, 3.5],
          },
        },
      ],
      products: [],
    },
    {
      section_id: 'internet',
      id: 'extender',
      icon: 'icon_extender',
      title: 'Aðgangspunktur',
      description:
        'Með WiFi netdreifi passar þú upp á að tengingin sé í topp formi hvort sem þú ert að hlaða rafskútuna í skúrnum eða í eldhúsinu að taka tímann á sunnudagssteikinni.',
      instances: [
        {
          position: [0.1366, 1.6673, -0.5182],
          tooltip: 'above',
          lookAt: {
            position: [-0.2, 1.6673, 3.5],
            rotation: [0, -Math.PI / 12, 0],
          },
        },
        {
          tooltip: 'above',
          position: [1.3957, 0.7895, -0.4991],
          lookAt: {
            position: [1, 0.7895, 3.5],
            rotation: [0, -Math.PI / 12, 0],
          },
        },
        {
          tooltip: 'above',
          position: [2.7358, 1.6662, -0.5256],
          lookAt: {
            position: [2.4358, 1.4662, 3.5],
            rotation: [Math.PI / 36, -Math.PI / 12, 0],
          },
        },
      ],
      products: [],
    },
    {
      section_id: 'entertainment',
      id: 'speaker',
      icon: 'icon_speaker',
      title: 'Hátalari',
      description:
        'Þú getur hlustað á tónlist eða hlaðvarp á meðan þú hleypur um húsið og þú missir ekki af einu orði eða takti.',
      instances: [
        { tooltip: 'above', position: [1.6226, 0.118, 0.6055] },
        { tooltip: 'left', position: [0.9789, 0.2885, -0.3845] },
        { tooltip: 'above', position: [-1.8031, 0.6413, -0.4099] },

        { tooltip: 'above', position: [-0.4382, 1.0168, -0.4873] },
      ],
      products: [],
    },
    {
      section_id: 'entertainment',
      id: 'tv',
      icon: 'icon_tv',
      title: 'Sjónvarp',
      description:
        'Með sjónvarpsgræjunni fylgir stöðuhækkun og þú verður dagskrárstjóri heimilisins. Þú færð aðgang að öllu sem internetið hefur upp á að bjóða, beint í sjónvarpið.',
      instances: [{ position: [0.26, 1.3, -0.14], tooltip: 'left' }],
      products: [],
    },
    {
      section_id: 'entertainment',
      id: 'soundbar',
      icon: 'icon_soundbar',
      title: 'Sjónvarpsdót', // TODO Íslenska?
      description:
        'Sjónvarp er meira en bara línuleg dagskrá! Allskonar dót sem gerir imbakassann ennþá skemmtilegri.',
      instances: [{ position: [0.34, 1.115, -0.27], tooltip: 'below' }],
      products: [],
    },
    {
      section_id: 'entertainment',
      id: 'scooter',
      icon: 'icon_scooter',
      title: 'Rafskúta',
      description:
        'Snjallt heimili kallar á snjallan fararmáta. Rafskútan rúllar um á grænni orku og kemur þér fljótlega á milli staða. ',
      instances: [{ position: [-2.2, 0.3, 0] }],
      products: [],
    },
    {
      id: 'voice-control',
      section_id: 'home-control',
      description:
        'Þegar þú ert búinn að koma þér vel fyrir uppi í sófa með teppi og te, en fjarstýringin er víðsfjarri þá getur spjallstýring bjargað kvöldinu. Þú stjórnar græjunum með röddinni einni saman.',
      icon: 'icon_speaker',
      title: 'Raddstýring',
      instances: [
        { tooltip: 'left', position: [2.2436, 1.4824, -0.4413] },
        { tooltip: 'above', position: [0.5117, 1.4697, -0.4473] },
        { tooltip: 'below', position: [-1.7659, 1.0165, 0.6555] },
      ],
      products: [],
    },
    {
      section_id: 'home-control',
      description:
        'Besti sambýlingur sem hugsast getur. Hann heldur utan um allt snjallheimilið fyrir þig, þú bara segir honum hvað á að gera og hann lætur snjalldrauma þína rætast.',
      id: 'hub',
      icon: 'icon_hub',
      title: 'Hub', // TODO Íslenska?
      instances: [
        {
          tooltip: 'above',
          position: [1.0194, 0.2631, 0.4541],
          rotation: [0, -1.226843488813095, 0],
          quaternion: [0, -0.5756687521934509, 0, 0.8176829814910889],
        },
        {
          tooltip: 'above',
          position: [2.4517, 1.2004, -0.4493],
          rotation: [3.141592653589793, -1.213701899044474, 3.141592653589793],
          quaternion: [0, -0.8214479088783264, 0, 0.5702835321426392],
        },
        {
          tooltip: 'above',
          position: [0.7466, 1.0557, -0.0218],
          rotation: [0, -0.44778589458429624, 0],
          quaternion: [0, -0.22202707827091217, 0, 0.9750404953956604],
        },
        {
          tooltip: 'above',
          position: [2.7216, 0.3085, 0.4885],
          rotation: [3.141592653589793, -1.0462045902704111, 3.141592653589793],
          quaternion: [0, -0.8662735223770142, 0, 0.49956998229026794],
        },
        { tooltip: 'above', position: [-1.8308, 1.1871, 0.3871] },
      ],
      products: [],
    },
    {
      section_id: 'security',
      id: 'sensor',
      icon: 'icon_sensor',
      description:
        'Vertu með sérsveitina, slökkviliðið og landlækni í viðbragðsstöðu heima hjá þér. Þessi tæki halda heimilinu öruggu og láta vita um leið og eitthvað er í ólagi.',
      title: 'Öryggisskynjari',
      instances: [
        {
          tooltip: 'above',
          position: [-2.533, 0.1361, -0.4858],
          lookAt: {
            position: [-2.433, 0.1361, 2.5],
            rotation: [0, -Math.PI / 24, 0],
          },
        },
        {
          tooltip: 'left',
          position: [-0.1913, 0.1391, -0.4947],
          lookAt: {
            position: [-0.0913, 0.1391, 2.5],
            rotation: [0, -Math.PI / 24, 0],
          },
        },
      ],
      products: [],
    },
    {
      section_id: 'lighting',
      id: 'spotlight',
      icon: 'icon_bulb',
      description:
        'Ljósin kveikt en enginn heima? Engar áhyggjur, þú getur slökkt og kveikt eins og þú vilt og þú þarft ekki einu sinni að vera heima.',
      title: 'Ljósapera',
      instances: [
        {
          position: [2.2269, 0.5042, -0.435],
          tooltip: 'above',
        },
        {
          position: [2.6669, 1.2542, -0.435],
          tooltip: 'above',
        },
        {
          position: [-1.42, 1.6542, -0.1],
          tooltip: 'above',
        },
        {
          position: [1.2683, 0.5442, -0.435],
          tooltip: 'above',
        },
        {
          position: [1.31, 1.45, -0.18],
          tooltip: 'above',
        },
        {
          position: [2.7145, 0.6558, 0.3769],
        },
        {
          position: [-1.41, 1.6158, 0],
        },
      ],
      products: [],
    },
    {
      section_id: 'lighting',
      id: 'light-bar',
      icon: 'icon_lightbar',
      description:
        'Þú stýrir stemningunni! Rómantískt með kæró, eldhúspartý með vinunum eða hugguleg og hlý lýsing með kaffibollanum og blaðinu á morgnanna. Þú ræður, þetta er þín stemning.',
      title: 'Stemningsljós',
      instances: [
        {
          tooltip: 'above',
          width: 0.3,
          height: 0.1,
          position: [0.811, 0.14, -0.667],
        },
      ],
      products: [],
    },
    {
      section_id: 'lighting',
      id: 'bulb',
      description:
        'Þú vilt væntanlega ekki flóðlýsingu þegar þú laumast á klósettið um miðja nótt. Hreyfiskynjarinn er með það á hreinu og slekkur meira að segja ljósin á eftir þér.',
      icon: 'icon_ceilinglight',
      title: 'Hreyfiskynjari',
      instances: [
        {
          position: [-2.05, 0.8542, 0.1],
          tooltip: 'above',
        },

        {
          position: [-0.27, 0.8542, 0.1],
          tooltip: 'above',
        },
      ],
      products: [],
    },
  ],
};

// http://nova.is/barinn/vara/:slug

function getFirst(c: any) {
  if (c && c.length) {
    return c[0].transformedSrc;
  }

  return null;
}

// tslint:disable-next-line: no-default-export
export default (ctx: any) => {
  const { nova } = ctx.data;

  data.categories.forEach((cat) => {
    const id = camelCase(cat.id);

    if (nova[id]) {
      const products: any[] = nova[id].products.edges.map((e: any) => e.node);

      cat.products = products.map<Product>((p) => ({
        category_id: cat.id,
        id: p.id,
        description: p.description,
        link: `https://www.nova.is/barinn/vara/${p.handle}`,
        photo: getFirst(p.images.edges.map((x: any) => x.node)),
        price: parseFloat(p.priceRange.minVariantPrice.amount),
        type: (p.tags || []).includes('bundle') ? 'bundle' : 'product',
        title: p.title,
        vendor: p.vendor,
      }));
    } else {
      // tslint:disable-next-line: no-console
      console.log('missing nova ID', id, cat);
    }
  });

  return (
    <>
      <Helmet title="Nova Snjallheimilið" />

      <DataProvider data={data}>
        <Header sections={data.sections} />

        <Snjallheimilid data={data} />
      </DataProvider>
    </>
  );
};

// TODO: Change handles to correct ones
export const query = graphql`
  fragment Products on Nova_Collection {
    handle
    products(first: 100) {
      edges {
        node {
          handle
          id
          description
          images(first: 10) {
            edges {
              node {
                id
                transformedSrc
                altText
              }
            }
          }
          title
          vendor
          tags
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }

  query {
    nova {
      bulb: collectionByHandle(handle: "motion-sensor") {
        ...Products
      }
      securityCam: collectionByHandle(handle: "security-cam") {
        ...Products
      }
      securityCamOutdoor: collectionByHandle(handle: "security-cam-outdoor") {
        ...Products
      }
      doorbell: collectionByHandle(handle: "doorbell") {
        ...Products
      }
      lock: collectionByHandle(handle: "lock") {
        ...Products
      }
      thermo: collectionByHandle(handle: "thermo") {
        ...Products
      }
      smokeDetector: collectionByHandle(handle: "smoke-detector") {
        ...Products
      }
      router: collectionByHandle(handle: "router") {
        ...Products
      }
      extender: collectionByHandle(handle: "extender") {
        ...Products
      }
      antenna: collectionByHandle(handle: "antenna") {
        ...Products
      }
      speaker: collectionByHandle(handle: "speaker") {
        ...Products
      }
      voiceControl: collectionByHandle(handle: "voice-control") {
        ...Products
      }
      soundbar: collectionByHandle(handle: "soundbar") {
        ...Products
      }
      tv: collectionByHandle(handle: "snjallheimilid-tv") {
        ...Products
      }
      scooter: collectionByHandle(handle: "scooter") {
        ...Products
      }
      hub: collectionByHandle(handle: "hub") {
        ...Products
      }
      sensor: collectionByHandle(handle: "sensor") {
        ...Products
      }
      spotlight: collectionByHandle(handle: "snjallheimilid-lysing") {
        ...Products
      }
      lightBar: collectionByHandle(handle: "light-bar") {
        ...Products
      }
    }
  }
`;
