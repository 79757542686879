import React, { useMemo, forwardRef } from 'react';
import pick from 'lodash/pick';
import { Color, BackSide } from 'three';
import { Instance } from '../../../types';
import { useTexture } from '../../../hooks/use-loaded';

export const Bulb = forwardRef((props: Instance & { active: any }, r) => {
  const col = useMemo(() => new Color(props.color || 0xffffff), [props.color]);
  const alpha = useTexture('alphamap');

  return (
    <group {...pick(props, 'position', 'rotation', 'quaternion')}>
      <mesh ref={r}>
        <sphereBufferGeometry args={[0.0127, 16, 5]} attach="geometry"  />
        <meshBasicMaterial attach="material" alphaMap={alpha} alphaTest={0.5} />
      </mesh>
    </group>
  );
});
