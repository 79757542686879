import React, { useState, useRef, useEffect } from 'react';
import { useModel } from '../../../hooks/use-loaded';
import { AnimationMixer, LoopRepeat } from 'three';
import { useFrame } from 'react-three-fiber';
import { useNightTextures } from '../../../hooks/use-night-textures';

export const Clouds = (props: any) => {
  const gltf = useModel('clouds');

  const ref = useRef(); 

  useNightTextures(gltf.scene, ['Cloud_night_texture', 'Cloud_night_texture'], ['Cloud', 'Cloud2'])

  const [mixer] = useState(new AnimationMixer(ref.current));

  useEffect(() => {
    mixer
      .clipAction(gltf.animations[0], ref.current)!
      .setLoop(LoopRepeat, Infinity)
      .play();
  }, []);

  useFrame((_, delta) => {
    mixer.update(delta);
  });
  
  return (
    <group scale={[0.0254, 0.0254, 0.0254]} {...props}>
      <group ref={ref}>
        <primitive object={gltf.scene} />;
      </group>
    </group>
  );
};
