import React from 'react';
import { CanvasProps } from 'react-three-fiber';

import { UIContext } from '../../context/UIContext';
import { DataContext } from '../../context/DataContext';
import { LoadingContext } from '../../context/LoadingContext';

export let Canvas: React.FC<CanvasProps> = () => null;

if (typeof window !== 'undefined') {
  // tslint:disable-next-line: no-var-requires
  const C = require('react-three-fiber').Canvas;

  Canvas = React.memo(({ children, ...props }: CanvasProps) => {
    return (
      <LoadingContext.Consumer>
        {load => (
          <DataContext.Consumer>
            {data => (
              <UIContext.Consumer>
                {value => (
                  <>
                    <C {...props}>
                      <LoadingContext.Provider value={load}>
                        <DataContext.Provider value={data}>
                          <UIContext.Provider value={value}>{children}</UIContext.Provider>
                        </DataContext.Provider>
                      </LoadingContext.Provider>
                    </C>
                  </>
                )}
              </UIContext.Consumer>
            )}
          </DataContext.Consumer>
        )}
      </LoadingContext.Consumer>
    );
  });
}
