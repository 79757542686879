import React from 'react';
import styled from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';

import IconChevron from '../../assets/svg/chevron.svg';

const Root = styled.div`
  position: fixed;
  z-index: 11;
  top: 30px;
  right: 30px;
  left: auto;
  display: block;
  padding: 0;

  color: #fff;

  cursor: pointer;

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const Chevron = styled(IconChevron)<{ isActive: boolean }>`
  transform: rotate(${props => (props.isActive ? '180deg' : '0deg')});
`;

const Icon = styled.div`
  display: inline-flex;
  width: 43px;
`;

const Button = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 0 18px;
  height: 44px;
  background: ${variables.colors.red};
  color: #fff;
  border-radius: 50px;
  outline: none;

  cursor: pointer;
`;

interface MobileButtonProps {
  isActive: boolean;
  onClick: void;
  children: React.ReactNode;
}

export const MobileButton = ({ isActive, onClick, children }: MobileButtonProps) => {
  return (
    <Root>
      <Button onClick={onClick}>
        {children && (
          <Icon>
            {children}
          </Icon>
        )}
        <Chevron isActive={isActive} />
      </Button>
    </Root>
  );
};
