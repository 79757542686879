import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';
import { useSpring, animated } from 'react-spring';

interface WrapperProps {
  visible: boolean;
}

type ModalProps = WrapperProps & {
  children?: React.ReactNode;
};

const ModalWrapper = styled(animated.div)`
  position: fixed;
  left: 100%;
  top: 0;
  bottom: 0;

  z-index: 3;

  width: 100vw;
  padding: 100px 0px 0 0px;

  background: ${variables.colors.boneWhite};

  pointer-events: none;

  will-change: transform;

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 140px;
  }

/*
  &::before {
    content: '';
    height: 140px;
    top: 0;
    width: 100%;

    background: #fff;
    box-shadow: 0 3px 65px 0 rgba(32, 32, 32, 0.125);
  }
*/
`;

const Inner = styled.div`
  height: 100%;
  width: 100%;

  padding: 60px 0px 140px 0px;

  overflow: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

export const Modal = ({ visible, children }: ModalProps) => {
  const styles = useSpring({
    transform: visible ? 'translate3d(-100%, 0,0)' : 'translate3d(0%,0,0)',
    pointerEvents: visible ? 'auto' : 'none',
  });

  return <ModalWrapper style={styles}><Inner>{children}</Inner></ModalWrapper>;
};
