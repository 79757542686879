import React, { useEffect, useRef, useState } from 'react';
import { useFrame } from 'react-three-fiber';
import { useModel } from '../../../hooks/use-loaded';
import { AnimationMixer, AnimationAction, LoopRepeat, MeshStandardMaterial } from 'three';
import { useDayNight } from '../../../hooks/use-day-night';
import { traverseMaterials } from '../../../utils/traverse-materials';

export function Bird() {
  const inner = useRef<THREE.Group>(null);
  const gltf = useModel('bird');
  const actions = useRef<{
    bird: AnimationAction;
  }>();

  const [mixer] = useState(() => new AnimationMixer(inner.current));

  const isNight = useDayNight();

  useFrame((_, delta) => {
    mixer.update(delta);
  });

  useEffect(() => {
    traverseMaterials(inner.current, (mat: MeshStandardMaterial) => {
      if (isNight === 1.0) {
        mat.envMapIntensity = 0.5;
        mat.color.set(0x000000);
      } else {
        mat.envMapIntensity = 1.0;
        mat.color.set(0xffffff);
      }

      mat.needsUpdate = true;
    });
  }, [isNight]);

  useEffect(() => {
    actions.current = {
      bird: mixer
        .clipAction(gltf.animations[0], inner.current)
        .setLoop(LoopRepeat, Infinity)
        .play(),
    };

    actions.current.bird.enabled = true;

    return () => {
      gltf.animations.forEach((clip: any) => mixer.uncacheClip(clip));
    };
  }, []);

  return (
    <group scale={[0.0254, 0.0254, 0.0254]}>
      <group ref={inner}>
        <primitive object={gltf.scene.children[0]} />
      </group>
    </group>
  );
}
