import React, { useEffect, useRef, useState } from 'react';
import { useFrame } from 'react-three-fiber';
import { useModel } from '../../../hooks/use-loaded';
import { AnimationAction, AnimationMixer, LoopOnce, LoopRepeat, Group } from 'three';

export function Cat(props: any) {
  const inner = useRef<Group>(null);
  const gltf = useModel('cat');
  const actions = useRef<{
    cat: AnimationAction;
  }>();

  const [mixer] = useState(() => new AnimationMixer(inner.current));

  useFrame((_, delta) => {
    mixer.update(delta);
  });

  useEffect(() => {
    if (!actions.current) {
      return;
    }
    if (props.active) {
      actions.current.cat
        .reset()
        .setLoop(LoopRepeat, Infinity)
        .play();
    } else {
      actions.current.cat!.setLoop(LoopOnce, 1).fadeOut(0.4);
    }
  }, [actions.current, props.active]);

  useEffect(() => {
    actions.current = {
      cat: mixer.clipAction(gltf.animations[0], inner.current),
    };
    actions.current.cat.enabled = true;

    return () => {
      gltf.animations.forEach((clip: any) => mixer.uncacheClip(clip));
    };
  }, []);

  return (
    <group scale={[0.0254, 0.0254, 0.0254]} position={props.position || [0,0,0]}>
      <group ref={inner}>
        <primitive object={gltf.scene.children[0]} />
      </group>
    </group>
  );
}
