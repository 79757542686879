import React, { useContext } from 'react';
import { Robber } from './objects/Robber';
import { UIContext } from '../../context/UIContext';
import { DeviceRenderer } from './objects/DeviceRenderer';

export const Security = () => {
  const { activeSection } = useContext(UIContext);

  return (
    <>
      <Robber active={activeSection === 'security'} position={[0, 0,0 ]} />
      <DeviceRenderer section="security" />
    </>
  );
};
