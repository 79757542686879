import React, { useMemo, useEffect, useRef, forwardRef } from 'react';
import pick from 'lodash/pick';
import { Color, BackSide } from 'three';
import { Instance } from '../../../types';
import { a } from 'react-spring/three';

export const LightBar = forwardRef((props: Instance & { active: any }, r) => {
  const col = useMemo(() => new Color(props.color || 0xffffff), [props.color]);

  return (
    <group {...pick(props, 'position', 'rotation', 'quaternion')}>
      <mesh ref={r}>
        <planeBufferGeometry args={[props.width, props.height]} attach="geometry" side={BackSide} />
        <a.meshStandardMaterial
          attach="material"
          color={0x303030}
          opacity={1}
          emissiveIntensity={props.active}
          emissive={col}
        />
      </mesh>
      {/* <a.spotLight
        position={[0, 0, 0]}
        ref={light}
        angle={props.angle || Math.PI / 2}
        decay={props.decay || 2}
        distance={props.distance || 30}
        color={col}
        intensity={interp}
        castShadow={false}
      >
        <object3D ref={target} position={props.target} />
      </a.spotLight> */}
    </group>
  );
});
