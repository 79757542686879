import { MeshStandardMaterial, Shader, WebGLRenderer, MeshStandardMaterialParameters, Texture } from 'three';

interface MixMaterialProps {
  otherMap: Texture;
  mixFactor: number;
}

export class MixMaterial extends MeshStandardMaterial {
  otherMap: {
    value: Texture;
  };
  mixFactor: {
    value: number;
  };

  constructor(args: MeshStandardMaterialParameters & MixMaterialProps) {
    super(args);

    this.otherMap = {
      value: args.otherMap,
    };

    this.mixFactor = {
      value: args.mixFactor,
    };
  }

  onBeforeCompile(shader: Shader, renderer: WebGLRenderer) {
    shader.uniforms.mixFactor = this.mixFactor;
    shader.uniforms.otherMap = this.otherMap;

    shader.fragmentShader = shader.fragmentShader
      .replace(
        '#include <map_pars_fragment>',
        `
    #ifdef USE_MAP
      uniform sampler2D map;
      uniform sampler2D otherMap;
      uniform float mixFactor;
    #endif
    `
      )
      .replace(
        '#include <map_fragment>',
        `
    #ifdef USE_MAP
      vec4 texelColor = texture2D( map, vUv );
      vec4 otherTexelColor = texture2D( otherMap, vUv );

      vec4 resultTexelColor = mix(texelColor, otherTexelColor, mixFactor);
      resultTexelColor = mapTexelToLinear( resultTexelColor );
      diffuseColor *= resultTexelColor;
    #endif
    `
      )
      .replace(
        '#include <common>',
        `
#define PI 3.14159265359
#define PI2 6.28318530718
#define PI_HALF 1.5707963267949
#define RECIPROCAL_PI 0.31830988618
#define RECIPROCAL_PI2 0.15915494
#define LOG2 1.442695
#define EPSILON 1e-6
#ifndef saturate
// <tonemapping_pars_fragment> may have defined saturate() already
#define saturate(a) clamp( a, 0.0, 1.0 )
#endif
#define whiteComplement(a) ( 1.0 - saturate( a ) )
float pow2( const in float x ) { return x*x; }
float pow3( const in float x ) { return x*x*x; }
float pow4( const in float x ) { float x2 = x*x; return x2*x2; }
float average( const in vec3 color ) { return dot( color, vec3( 0.3333 ) ); }
// expects values in the range of [0,1]x[0,1], returns values in the [0,1] range.
// do not collapse into a single function per: http://byteblacksmith.com/improvements-to-the-canonical-one-liner-glsl-rand-for-opengl-es-2-0/
highp float rand( const in vec2 uv ) {
	const highp float a = 12.9898, b = 78.233, c = 43758.5453;
	highp float dt = dot( uv.xy, vec2( a,b ) ), sn = mod( dt, PI );
	return fract(sin(sn) * c);
}
#ifdef HIGH_PRECISION
	float precisionSafeLength( vec3 v ) { return length( v ); }
#else
	float max3( vec3 v ) { return max( max( v.x, v.y ), v.z ); }
	float precisionSafeLength( vec3 v ) {
		float maxComponent = max3( abs( v ) );
		return length( v / maxComponent ) * maxComponent;
	}
#endif
struct IncidentLight {
	vec3 color;
	vec3 direction;
  bool visible;
  float ratio;
};
struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};
struct GeometricContext {
	vec3 position;
	vec3 normal;
	vec3 viewDir;
#ifdef CLEARCOAT
	vec3 clearcoatNormal;
#endif
};
vec3 transformDirection( in vec3 dir, in mat4 matrix ) {
	return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );
}
// http://en.wikibooks.org/wiki/GLSL_Programming/Applying_Matrix_Transformations
vec3 inverseTransformDirection( in vec3 dir, in mat4 matrix ) {
	return normalize( ( vec4( dir, 0.0 ) * matrix ).xyz );
}
vec3 projectOnPlane(in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal ) {
	float distance = dot( planeNormal, point - pointOnPlane );
	return - distance * planeNormal + point;
}
float sideOfPlane( in vec3 point, in vec3 pointOnPlane, in vec3 planeNormal ) {
	return sign( dot( point - pointOnPlane, planeNormal ) );
}
vec3 linePlaneIntersect( in vec3 pointOnLine, in vec3 lineDirection, in vec3 pointOnPlane, in vec3 planeNormal ) {
	return lineDirection * ( dot( planeNormal, pointOnPlane - pointOnLine ) / dot( planeNormal, lineDirection ) ) + pointOnLine;
}
mat3 transposeMat3( const in mat3 m ) {
	mat3 tmp;
	tmp[ 0 ] = vec3( m[ 0 ].x, m[ 1 ].x, m[ 2 ].x );
	tmp[ 1 ] = vec3( m[ 0 ].y, m[ 1 ].y, m[ 2 ].y );
	tmp[ 2 ] = vec3( m[ 0 ].z, m[ 1 ].z, m[ 2 ].z );
	return tmp;
}
// https://en.wikipedia.org/wiki/Relative_luminance
float linearToRelativeLuminance( const in vec3 color ) {
	vec3 weights = vec3( 0.2126, 0.7152, 0.0722 );
	return dot( weights, color.rgb );
}
bool isPerspectiveMatrix( mat4 m ) {
  return m[ 2 ][ 3 ] == - 1.0;
}
      `
      )
      .replace(
        '#include <lights_pars_begin>',
        `

      uniform vec3 ambientLightColor;
uniform vec3 lightProbe[ 9 ];
// get the irradiance (radiance convolved with cosine lobe) at the point 'normal' on the unit sphere
// source: https://graphics.stanford.edu/papers/envmap/envmap.pdf
vec3 shGetIrradianceAt( in vec3 normal, in vec3 shCoefficients[ 9 ] ) {
	// normal is assumed to have unit length
	float x = normal.x, y = normal.y, z = normal.z;
	// band 0
	vec3 result = shCoefficients[ 0 ] * 0.886227;
	// band 1
	result += shCoefficients[ 1 ] * 2.0 * 0.511664 * y;
	result += shCoefficients[ 2 ] * 2.0 * 0.511664 * z;
	result += shCoefficients[ 3 ] * 2.0 * 0.511664 * x;
	// band 2
	result += shCoefficients[ 4 ] * 2.0 * 0.429043 * x * y;
	result += shCoefficients[ 5 ] * 2.0 * 0.429043 * y * z;
	result += shCoefficients[ 6 ] * ( 0.743125 * z * z - 0.247708 );
	result += shCoefficients[ 7 ] * 2.0 * 0.429043 * x * z;
	result += shCoefficients[ 8 ] * 0.429043 * ( x * x - y * y );
	return result;
}
vec3 getLightProbeIrradiance( const in vec3 lightProbe[ 9 ], const in GeometricContext geometry ) {
	vec3 worldNormal = inverseTransformDirection( geometry.normal, viewMatrix );
	vec3 irradiance = shGetIrradianceAt( worldNormal, lightProbe );
	return irradiance;
}
vec3 getAmbientLightIrradiance( const in vec3 ambientLightColor ) {
	vec3 irradiance = ambientLightColor;
	#ifndef PHYSICALLY_CORRECT_LIGHTS
		irradiance *= PI;
	#endif
	return irradiance;
}
#if NUM_DIR_LIGHTS > 0
	struct DirectionalLight {
		vec3 direction;
		vec3 color;
		int shadow;
		float shadowBias;
		float shadowRadius;
		vec2 shadowMapSize;
	};
	uniform DirectionalLight directionalLights[ NUM_DIR_LIGHTS ];
	void getDirectionalDirectLightIrradiance( const in DirectionalLight directionalLight, const in GeometricContext geometry, out IncidentLight directLight ) {
		directLight.color = directionalLight.color;
		directLight.direction = directionalLight.direction;
		directLight.visible = true;
	}
#endif
#if NUM_POINT_LIGHTS > 0
	struct PointLight {
		vec3 position;
		vec3 color;
		float distance;
		float decay;
		int shadow;
		float shadowBias;
		float shadowRadius;
		vec2 shadowMapSize;
		float shadowCameraNear;
		float shadowCameraFar;
	};
	uniform PointLight pointLights[ NUM_POINT_LIGHTS ];
	// directLight is an out parameter as having it as a return value caused compiler errors on some devices
	void getPointDirectLightIrradiance( const in PointLight pointLight, const in GeometricContext geometry, out IncidentLight directLight ) {
		vec3 lVector = pointLight.position - geometry.position;
		directLight.direction = normalize( lVector );
		float lightDistance = length( lVector );
		directLight.color = pointLight.color;
		directLight.color *= punctualLightIntensityToIrradianceFactor( lightDistance, pointLight.distance, pointLight.decay );
		directLight.visible = ( directLight.color != vec3( 0.0 ) );
	}
#endif
#if NUM_SPOT_LIGHTS > 0
	struct SpotLight {
		vec3 position;
		vec3 direction;
		vec3 color;
		float distance;
		float decay;
		float coneCos;
		float penumbraCos;
		int shadow;
		float shadowBias;
		float shadowRadius;
		vec2 shadowMapSize;
	};
	uniform SpotLight spotLights[ NUM_SPOT_LIGHTS ];
	// directLight is an out parameter as having it as a return value caused compiler errors on some devices
	void getSpotDirectLightIrradiance( const in SpotLight spotLight, const in GeometricContext geometry, out IncidentLight directLight  ) {
		vec3 lVector = spotLight.position - geometry.position;
		directLight.direction = normalize( lVector );
		float lightDistance = length( lVector );
		float angleCos = dot( directLight.direction, spotLight.direction );
		if ( angleCos > spotLight.coneCos ) {
			float spotEffect = smoothstep( spotLight.coneCos, spotLight.penumbraCos, angleCos );
      directLight.color = spotLight.color;
      
      directLight.ratio = spotEffect * punctualLightIntensityToIrradianceFactor( lightDistance, spotLight.distance, spotLight.decay );
      directLight.color *= directLight.ratio;
			directLight.visible = true;
		} else {
      directLight.ratio = 0.0;
			directLight.color = vec3( 0.0 );
			directLight.visible = false;
		}
	}
#endif
#if NUM_RECT_AREA_LIGHTS > 0
	struct RectAreaLight {
		vec3 color;
		vec3 position;
		vec3 halfWidth;
		vec3 halfHeight;
	};
	// Pre-computed values of LinearTransformedCosine approximation of BRDF
	// BRDF approximation Texture is 64x64
	uniform sampler2D ltc_1; // RGBA Float
	uniform sampler2D ltc_2; // RGBA Float
	uniform RectAreaLight rectAreaLights[ NUM_RECT_AREA_LIGHTS ];
#endif
#if NUM_HEMI_LIGHTS > 0
	struct HemisphereLight {
		vec3 direction;
		vec3 skyColor;
		vec3 groundColor;
	};
	uniform HemisphereLight hemisphereLights[ NUM_HEMI_LIGHTS ];
	vec3 getHemisphereLightIrradiance( const in HemisphereLight hemiLight, const in GeometricContext geometry ) {
		float dotNL = dot( geometry.normal, hemiLight.direction );
		float hemiDiffuseWeight = 0.5 * dotNL + 0.5;
		vec3 irradiance = mix( hemiLight.groundColor, hemiLight.skyColor, hemiDiffuseWeight );
		#ifndef PHYSICALLY_CORRECT_LIGHTS
			irradiance *= PI;
		#endif
		return irradiance;
	}
#endif
      `
      )
      .replace(
        '#include <lights_fragment_begin>',
        `
        /**
         * This is a template that can be used to light a material, it uses pluggable
         * RenderEquations (RE)for specific lighting scenarios.
         *
         * Instructions for use:
         * - Ensure that both RE_Direct, RE_IndirectDiffuse and RE_IndirectSpecular are defined
         * - If you have defined an RE_IndirectSpecular, you need to also provide a Material_LightProbeLOD. <---- ???
         * - Create a material parameter that is to be passed as the third parameter to your lighting functions.
         *
         * TODO:
         * - Add area light support.
         * - Add sphere light support.
         * - Add diffuse light probe (irradiance cubemap) support.
         */
        
        GeometricContext geometry;
        
        geometry.position = - vViewPosition;
        geometry.normal = normal;
        geometry.viewDir = normalize( vViewPosition );
        
        #ifdef CLEARCOAT
        
          geometry.clearcoatNormal = clearcoatNormal;
        
        #endif
        
        IncidentLight directLight;
        
        #if ( NUM_POINT_LIGHTS > 0 ) && defined( RE_Direct )
        
          PointLight pointLight;
        
          #pragma unroll_loop
          for ( int i = 0; i < NUM_POINT_LIGHTS; i ++ ) {
        
            pointLight = pointLights[ i ];
        
            getPointDirectLightIrradiance( pointLight, geometry, directLight );
        
            #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_POINT_LIGHT_SHADOWS )
            directLight.color *= all( bvec2( pointLight.shadow, directLight.visible ) ) ? getPointShadow( pointShadowMap[ i ], pointLight.shadowMapSize, pointLight.shadowBias, pointLight.shadowRadius, vPointShadowCoord[ i ], pointLight.shadowCameraNear, pointLight.shadowCameraFar ) : 1.0;
            #endif
        
            RE_Direct( directLight, geometry, material, reflectedLight );
        
          }
        
        #endif
        
        #if ( NUM_SPOT_LIGHTS > 0 ) && defined( RE_Direct )
        
          SpotLight spotLight;
        
          #pragma unroll_loop
          for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {
        
            spotLight = spotLights[ i ];
        
            getSpotDirectLightIrradiance( spotLight, geometry, directLight );
        
            #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_SPOT_LIGHT_SHADOWS )
            directLight.color *= all( bvec2( spotLight.shadow, directLight.visible ) ) ? getShadow( spotShadowMap[ i ], spotLight.shadowMapSize, spotLight.shadowBias, spotLight.shadowRadius, vSpotShadowCoord[ i ] ) : 1.0;
            #endif
            
            material.diffuseColor = mix(material.diffuseColor, texelColor.rgb, directLight.ratio);
            RE_Direct( directLight, geometry, material, reflectedLight );
        
          }
        
        #endif
        
        #if ( NUM_DIR_LIGHTS > 0 ) && defined( RE_Direct )
        
          DirectionalLight directionalLight;
        
          #pragma unroll_loop
          for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {
        
            directionalLight = directionalLights[ i ];
        
            getDirectionalDirectLightIrradiance( directionalLight, geometry, directLight );
        
            #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_DIR_LIGHT_SHADOWS )
            float lightFactor = all( bvec2( directionalLight.shadow, directLight.visible ) ) ? getShadow( directionalShadowMap[ i ], directionalLight.shadowMapSize, directionalLight.shadowBias, directionalLight.shadowRadius, vDirectionalShadowCoord[ i ] ) : 1.0;
            directLight.color *= lightFactor;
            material.diffuseColor = mix(material.diffuseColor, texelColor.rgb, lightFactor);
            #endif
        
            RE_Direct( directLight, geometry, material, reflectedLight );
        
          }
        
        #endif
        
        #if ( NUM_RECT_AREA_LIGHTS > 0 ) && defined( RE_Direct_RectArea )
        
          RectAreaLight rectAreaLight;
        
          #pragma unroll_loop
          for ( int i = 0; i < NUM_RECT_AREA_LIGHTS; i ++ ) {
        
            rectAreaLight = rectAreaLights[ i ];
            RE_Direct_RectArea( rectAreaLight, geometry, material, reflectedLight );
        
          }
        
        #endif
        
        #if defined( RE_IndirectDiffuse )
        
          vec3 iblIrradiance = vec3( 0.0 );
        
          vec3 irradiance = getAmbientLightIrradiance( ambientLightColor );
        
          irradiance += getLightProbeIrradiance( lightProbe, geometry );
        
          #if ( NUM_HEMI_LIGHTS > 0 )
        
            #pragma unroll_loop
            for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
        
              irradiance += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
        
            }
        
          #endif
        
        #endif
        
        #if defined( RE_IndirectSpecular )
        
          vec3 radiance = vec3( 0.0 );
          vec3 clearcoatRadiance = vec3( 0.0 );
        
        #endif
        
      `
      );
  }
}
