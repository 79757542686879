import * as React from 'react';
import styled from 'styled-components';

import { useSpring, config } from 'react-spring/three';
import { animated, useSpring as htmlSpring } from 'react-spring';

import { Data } from '../../types';
import { Intro } from '../intro/Intro';
import { Zoom } from '../zoom/Zoom';
import { RightArrowButton } from '../button/Button';
import { Scene } from '../scene/Scene';

import { UIContext } from '../../context/UIContext';
import { CategoryModal, CategoryDetails } from '../category-modal/CategoryModal';
import { BundleModal } from '../bundle-modal/BundleModal';

import { TooltipContainer } from '../tooltip/Tooltip';
import { LoadingContext, INITIAL, DONE, RENDERING, POST_RENDER_CONFIG, INTERACTIVE } from '../../context/LoadingContext';

import { Share } from '../share/Share';
import { useKiosk } from '../../hooks/use-kiosk';

const Viewport = styled(animated.div)`
  position: fixed;
  top: 0;
  height: 100%;

  width: 100vw;

  z-index: 2;
`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const SceneContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;

  z-index: 1;
`;

const Wall = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
`;

const introTween = (x: any) => ({
  transform: x.interpolate({ range: [0, 1], output: [0, 100] }).interpolate(v => `translate3d(0,${-v}px,0)`),
  opacity: x.interpolate({
    range: [0, 1],
    output: [1, 0],
    extrapolate: 'clamp',
  }),
  pointerEvents: x.interpolate((v: any) => (v > 0.5 ? 'none' : 'auto')),
});

interface SnjallheimilidProps {
  data: Data;
}

export const Snjallheimilid: React.FC<SnjallheimilidProps> = ({ data }) => {
  if (typeof window === undefined) {
    return null;
  }

  const isKiosk = useKiosk();
  const [shouldLoad, setShouldLoad] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [zoom, setZoom] = React.useState(1);

  const ctx = React.useContext(UIContext);
  const { loadingState, totalBytes, recvBytes, start, setLoadingState } = React.useContext(LoadingContext);

  const props = htmlSpring({ x: Number(ctx.introPlayed), config: config.gentle });

  const categoryDetails = React.useMemo<CategoryDetails | null>(() => {
    if (ctx.selectedCategory === null) {
      return null;
    }

    const category = data.categories.find(v => v.id === ctx.selectedCategory![0])!;

    return {
      ...category,
      section: data.sections.find(v => v.id === category!.section_id)!,
    };
  }, [ctx.selectedCategory]);

  React.useEffect(() => {
    if (ctx.activeSection && ctx.isMobile) {
      setZoom(4);
    }
  }, [ctx.activeSection, ctx.isMobile]);

  React.useEffect(() => {
    if (shouldLoad && loadingState === INITIAL) {
      start();
    }
  }, [shouldLoad, loadingState]);

  React.useEffect(() => {
    if (isLoaded) {
      setLoadingState(DONE);
    }
  }, [isLoaded]);

  const handleCtaClick = () => {
    ctx.setIntroPlayed(true);
  };

  const handleModalClose = () => {
    ctx.setSelectedCategory(null);
  };

  const handleZoomChange = (val: number) => {
    setZoom(val);
  };

  return (
    <>
      <Wrapper>
        <TooltipContainer />
        {loadingState >= RENDERING && (
          <SceneContainer>
            <Scene zoom={zoom} />
          </SceneContainer>
        )}

        {loadingState < INTERACTIVE && (
          <Viewport style={introTween(props.x)}>
            <Wall>
              <Intro
                loaded={loadingState >= POST_RENDER_CONFIG}
                progress={Math.round((recvBytes / (totalBytes + 0.0001)) * 100)}
                onLoadStart={() => setShouldLoad(true)}
                onLoadEnd={() => setIsLoaded(true)}
                heading="Snjallheimilið"
                description={`Láttu eins og heima hjá þér! Kíktu í heimsókn í Snjallheimili Nova þar sem þú getur stýrt stemningunni, haft öryggið snjallt og sótt þér alla þá afþreyingu sem þú girnist.`}
                cta={
                  <RightArrowButton size="big" width="full" onClick={handleCtaClick}>
                    Áfram
                  </RightArrowButton>
                }
              />
            </Wall>
          </Viewport>
        )}

        <Zoom visible={ctx.introPlayed} current={zoom} onChange={handleZoomChange} high={4} low={1} />
      </Wrapper>

      {loadingState >= RENDERING && (
        <>
          <CategoryModal category={categoryDetails} onClose={handleModalClose} />

          <BundleModal onClose={handleModalClose} />
        </>
      )}

      <Share night visible={isLoaded && !isKiosk} />
    </>
  );
};
