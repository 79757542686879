import React, { forwardRef, useEffect } from 'react';
import { Mesh, LinearFilter, RepeatWrapping, sRGBEncoding, RGBFormat, UVMapping } from 'three';
import { useModel, useTexture } from '../../../hooks/use-loaded';

interface DeviceProps {
  model: string;
  index?: number;
  [key: string]: any;
}

export const Device = forwardRef<Mesh, DeviceProps>(({ model, index = 1, ...props }: DeviceProps, ref) => {
  const gltf = useModel(model);
  const t = useTexture('devices_texture');

  useEffect(() => {
    t.wrapS = t.wrapT = RepeatWrapping;
    t.anisotropy = 1;
    t.encoding = sRGBEncoding;
    t.format = RGBFormat;
    t.mapping = UVMapping;
    t.flipY = false;
    t.minFilter = LinearFilter;
  }, [t]);

  return (
    <group {...props} scale={[0.0254, 0.0254, 0.0254]}>
      <mesh name={model} userData-measure={true} ref={ref}>
        <bufferGeometry attach="geometry" {...(gltf.__$[index] as any).geometry} />
        <meshBasicMaterial attach="material" map={t} />
      </mesh>
    </group>
  );
});
