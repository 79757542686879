import fragmentShader from 'raw-loader!./fragment/wifi.glsl';
import vertexShader from 'raw-loader!./vertex/wifi.glsl';

export const Wifi = () => ({
  uniforms: {
    time: {
      value: 0.0,
    },
    gap: {
      value: 0.2,
    },
    duration: {
      value: 16.0,
    },
    opacity: {
      value: 0.0,
    },
  },
  fragmentShader,
  vertexShader,
});
