import React, { forwardRef } from 'react';
import { Device } from './Device';
import { WifiWave } from './WifiWave';
import { useModel } from '../../../hooks/use-loaded';
import { useNightTextures } from '../../../hooks/use-night-textures';

export const Antenna = forwardRef((props: any, ref) => {
  const gltf = useModel('antenna');

  useNightTextures(gltf.scene, ['Roof_night_texture'], ['Antenna']);

  return (
    <>
      <primitive object={gltf.scene} ref={ref} scale={[0.0254, 0.0254, 0.0254]} />
      <WifiWave active={props.active} position={[2.675, 2.7, 0]} />
    </>
  );
});
