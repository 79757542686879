import React from 'react';
import styled from 'styled-components';

import { responsiveFont, formatPrice } from '../../styles/utils';
import { breakpoints, variables } from '../../styles/variables';

import Arrow from '../../assets/svg/arrow-cta.svg';
import { useKiosk } from '../../hooks/use-kiosk';

interface BlockTextProps {
  heading: string;
  kicker: string;
  price: number;
  image: string;
  link: string;
  text?: string;
}

const Kicker = styled.div`
  ${responsiveFont(12, 14)};

  margin-bottom: 5px;

  font-weight: 500;
  color: ${variables.colors.red};
  letter-spacing: 0;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 8px;
  }
`;

const Product = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;

  position: relative;

  width: 100%;
  height: auto;
`;

const Price = styled.div`
  ${responsiveFont(14, 18)};
  position: relative;

  font-weight: 400;
  color: ${variables.colors.font};
  letter-spacing: 0;
  line-height: ${34 / 18};
`;

const Heading = styled.h3`
  ${responsiveFont(18, 24)}

  margin-bottom: 10px;

  font-weight: 700;
  color: ${variables.colors.font};
  letter-spacing: 0;
  line-height: ${28 / 24};
  word-wrap: break-word;
`;

const Text = styled.div`
  margin-bottom: 10px;

  font-weight: 300;
  font-size: 14px;
  color: #232323;
  letter-spacing: 0;
  line-height: 22px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;

  margin-top: -70px;
  height: 220px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: -125px;
    height: 280px;
  }

  @media (min-width: ${breakpoints.lg}) {
    height: 320px;
  }

  &::after {
    content: '';

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    height: 60px;

    box-shadow: 0 3px 65px 0 rgba(32, 32, 32, 0.125);

    @media (min-width: ${breakpoints.md}) {
      box-shadow: 0 5px 65px 0 rgba(32, 32, 32, 0.125);
    }
  }
`;

const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  position: relative;
  z-index: 2;

  width: 100%;
  height: 100%;

  background-color: #fff;
  box-shadow: inset 0 0 0 0 transparent;

  transition-property: box-shadow, background-color;
  transition-duration: 220ms;
  transition-timing-function: ease-in-out;
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 3;

  margin-top: 30px;
  min-height: 200px;

  @media (min-width: ${breakpoints.md}) {
    height: inherit;
  }
`;

const Image = styled.img`
  position: relative;

  margin-left: auto;
  margin-right: auto;

  padding-left: 30px;
  padding-right: 30px;

  max-height: 260px;
  display: block;
  max-width: 100%;
  height: auto;

  pointer-events: none;
  user-select: none;

  transition: 220ms transform ease-in-out;

  @media (min-width: ${breakpoints.lg}) {
    max-height: 180px;
    transform: translateY(0);
  }
`;

const Link = styled.a`
  text-decoration: none;
  user-select: none;

  &:hover {
    ${DetailsWrap} {
      box-shadow: inset 0 0 0 4px ${variables.colors.red};
    }
    ${Image} {
      @media (min-width: ${breakpoints.lg}) {
        transform: translateY(-10px) scale(0.95);
      }
    }
  }
`;

const Content = styled.div`
  align-self: flex-end;

  padding: 40px 20px 30px 20px;

  width: 100%;

  @media (min-width: ${breakpoints.lg}) {
    padding: 0 35px 30px;
  }
`;

const PriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export function ProductItem({
  kicker,
  heading,
  text,
  price,
  image,
  link,
}: BlockTextProps) {
  const isKiosk = useKiosk();

  let details = (
    <>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <Details>
        <DetailsWrap>
          <Content>
            <Kicker>{kicker}</Kicker>
            <Heading>{heading}</Heading>
            {text && <Text>{text}</Text>}
            <PriceWrap>
              <Price>{formatPrice(price)}</Price>
              <Arrow />
            </PriceWrap>
          </Content>
        </DetailsWrap>
      </Details>
    </>
  );

  if (!isKiosk) {
    details = (
      <Link target="_new" href={link} title={heading}>
        {details}
      </Link>
    );
  }

  return <Product>{details}</Product>;
}
