import React from 'react';
import styled from 'styled-components';

import { breakpoints, variables } from '../../styles/variables';

import Arrow from '../../assets/svg/arrow.svg';

interface IProps {
  disabled?: boolean;
  onClick?: () => void;
}

const ArrowIcon = styled(Arrow)`
  height: 20px;
  width: 12px;

  transition: transform .18s;

  @media (min-width: ${breakpoints.md}) {
    height: 29px;
    width: 17px;
  }
`;

const Root = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  position: relative;

  margin: 0;

  width: 40px;
  height: 40px;

  color: ${variables.colors.red};

  cursor: pointer;
  pointer-events: auto;
  user-select: none;
  outline: none;
  background: transparent;

  transition: color 300ms ease-in-out;

  &:disabled,
  &[disabled]{
    color: #a6a6a6;
  }

  &:hover {
    ${ArrowIcon} {
      transform: scale(0.9);
    }
  }
`;


export function CarouselButton({ disabled, onClick }: IProps) {
  return (
    <Root
      onClick={onClick}
      disabled={disabled}
    >
      <ArrowIcon />
    </Root>
  );
}
