import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'gatsby';
import LogoSvg from '../../assets/svg/logo.svg';
import WordmarkSvg from '../../assets/svg/wordmark.svg';

import styled, { css } from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';
import { SectionSelector } from '../section-selector/SectionSelector';
import { MobileMenu } from '../mobile-menu/MobileMenu';
import { responsiveFont } from '../../styles/utils';
import { useTrail, useSpring, animated, config } from 'react-spring';
import { UIContext } from '../../context/UIContext';
import { Section } from '../../types';
import { IconButton } from '../button/Button';

interface NovaLogoProps {
  night: boolean;
}

const HeaderRoot = styled(animated.div)`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 30px;

  opacity: 0;
  transform: translate3d(0, -110%, 0);
  z-index: 4;

  will-change: transform, opacity;

  @media (min-width: ${breakpoints.lg}) {
    padding: 50px 50px 0;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  padding-top: 30px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 0;
  }
`;

const ContentWrap = styled.div`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: inline-flex;
  }
`;

const Logo = styled(LogoSvg)`
  display: block;

  height: 14px;
  width: auto;

  color: ${variables.colors.font};
  transition: color 300ms;

  @media (min-width: ${breakpoints.md}) {
    height: 17px;
  }
`;

const LogoWordmark = styled(WordmarkSvg)`
  margin-top: 8px;
  color: ${variables.colors.font};

  height: 24px;
  width: 165px;

  transition: color 300ms;

  @media (min-width: ${breakpoints.md}) {
    height: 24px;
    width: 164px;
  }
`;

const NovaLogo = styled(Link)<NovaLogoProps>`
  position: fixed;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  text-decoration: none;

  top: 30px;

  @media (min-width: ${breakpoints.lg}) {
    top: 50px;
  }

  ${props =>
    props.night &&
    css`
      ${Logo} {
        color: #fff;
      }

      ${LogoWordmark} {
        color: #fff;
      }
    `}
`;

const HeaderSelector = styled(SectionSelector)`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const TooltipBox = styled(animated.div)<Omit<TooltipProps, 'title'>>`
  position: fixed;
  z-index: 4;

  width: 310px;
  right: 30px;

  top: 100px;

  transform: translateY(-15%);
  text-align: center;

  @media (min-width: ${breakpoints.lg}) {
    top: 140px;
    left: 50%;
    right: auto;
    width: 440px;
    margin-left: -220px;
  }
`;

const Bubble = styled.button`
  position: relative;

  padding: 15px 20px 10px;

  color: #000;
  font-weight: 600;
  font-size: 14px;
  font-family: ${variables.font.familyHeading};

  border-radius: 38px;
  z-index: 1;
  background: #fff;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

  outline: none;
  cursor: pointer;

  &:after {
    position: absolute;
    content: '';
    bottom: calc(100% - 5px);

    right: 20px;

    width: 10px;
    height: 10px;

    background: #fff;
    z-index: 0;
    transform: translateX(-50%) rotate(45deg);
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 30px 40px 25px;

    font-size: 17px;

    &:after {
      bottom: calc(100% - 9px);
      left: 50%;
      right: auto;

      width: 18px;
      height: 18px;
    }
  }
`;

interface HeaderProps {
  sections: Section[];
}

export const Header = React.memo(({ sections }: HeaderProps) => {
  const {
    introPlayed,
    activeSection,
    setActiveSection,
    selectedCategory,
    isDesktop,

    setActiveBundle,
  } = useContext(UIContext);
  const [tooltipActive, setTooltipActive] = useState(false);
  const props = useSpring({
    opacity: introPlayed ? 1 : 0,
    transform: `translate3d(0, ${introPlayed && !selectedCategory ? 0 : -110}%, 0)`,
    config: { mass: 0.5, friction: 40 },
  });

  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(-15%)',
    pointerEvents: 'none',
    config: { mass: 0.2, friction: 40 },
  }));

  const handleSectionSelect = (section: string) => {
    if (section === 'bundles') {
      setActiveBundle(true);
    }

    if (section === activeSection) {
      setActiveSection(null);
    } else {
      setActiveSection(section);
    }
  };

  const openTooltip = () => {
    if (tooltipActive) {
      setTooltipProps({
        pointerEvents: 'auto',
        transform: 'translateY(0%)',
        opacity: 1,
        delay: 3500,
      });
    }
  };

  const closeTooltip = () => {
    setTooltipProps({
      transform: 'translateY(-15%)',
      opacity: 0,
      pointerEvents: 'none',
    });
    setTooltipActive(false);
  };

  useEffect(() => {
    if (tooltipActive) {
      openTooltip();
    }
  }, [tooltipActive]);

  useEffect(() => {
    if (introPlayed) {
      setTooltipActive(true);
    }
  }, [introPlayed]);

  useEffect(() => {
    closeTooltip();
    if (tooltipActive) {
      closeTooltip();
    }
  }, [activeSection]);

  return (
    <HeaderRoot style={props}>
      <Content>
        <NovaLogo to="/" night={activeSection !== 'bundles'}>
          <Logo />
          <LogoWordmark />
        </NovaLogo>
        {isDesktop && (
          <HeaderSelector
            sections={sections}
            activeSection={activeSection}
            onSectionSelect={handleSectionSelect}
          />
        )}
      </Content>

      {!isDesktop && (
        <MobileMenu
          sections={sections}
          activeSection={activeSection}
          onSectionSelect={handleSectionSelect}
          onMenuToggle={closeTooltip}
        />
      )}

      <TooltipBox style={tooltipProps}>
        <Bubble onClick={closeTooltip}>Veldu flokk til að skoða vörur í húsinu</Bubble>
      </TooltipBox>
    </HeaderRoot>
  );
});
