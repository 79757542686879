import { extend, useThree, useFrame } from 'react-three-fiber';
import { EffectComposer } from './postprocessing/EffectComposer';
import { RenderPass } from './postprocessing/RenderPass';
import { ShaderPass } from './postprocessing/ShaderPass';
import { FXAAShader } from './postprocessing/FXAAShader';
import { BloomPass } from './postprocessing/BloomPass';
import {
  LoadingContext,
  RENDERING,
  POST_RENDER_CONFIG,
  DONE,
} from '../../context/LoadingContext';
import React, { useRef, useEffect, useContext } from 'react';
import {
  ReinhardToneMapping,
  LinearToneMapping,
  MeshStandardMaterial,
  CubeTexture,
  FrontSide,
  Mesh,
} from 'three';

import { Bird } from './objects/Bird';
import { ChristmasGround } from './objects/ChristmasGround';
import { Clouds } from './objects/Clouds';
import { Security } from './Security';
import { Entertainment } from './Entertainment';
import { InternetWifi } from './InternetWifi';

import { Lighting } from './Lighting';
import { HomeControl } from './HomeControl';

import * as loaders from '../../utils/loaders';
import { ChristmasHouse } from './objects/ChristmasHouse';
import { MainSnowfall } from './objects/Snowfall';

extend({
  EffectComposer,
  RenderPass,
  ShaderPass,
  BloomPass,
});

const Done = () => {
  const { setLoadingState, get } = useContext(LoadingContext);
  const { scene } = useThree();

  const cube = [
    'cube-posx',
    'cube-negx',
    'cube-posy',
    'cube-negy',
    'cube-posz',
    'cube-negz',
  ];

  useEffect(
    () => () => {
      const textures = cube.map(get).map((d) => loaders.texture.load(d));

      const cubeTexture = new CubeTexture(textures.map((x) => x.image));

      scene.traverse((obj) => {
        obj.frustumCulled = false;

        if (obj instanceof Mesh) {
          if (
            obj.name !== 'disco' &&
            obj.material instanceof MeshStandardMaterial
          ) {
            if (obj.material.side !== FrontSide) {
              obj.material.side = FrontSide;
            }
            obj.material.envMap = cubeTexture;
            obj.material.metalness = 0;
            obj.material.roughness = 0.5;
            obj.material.needsUpdate = true;
          }
        }
      });

      setLoadingState(POST_RENDER_CONFIG);
    },
    []
  );

  return null;
};

export const Main = () => {
  const { gl, camera, size, scene } = useThree();

  const composer = useRef<EffectComposer>();
  const { loadingState } = useContext(LoadingContext);

  useEffect(() => {
    composer.current!.setSize(size.width, size.height);
  }, [size, scene]);

  useFrame(() => {
    if (loadingState >= RENDERING) {
      gl.toneMapping = ReinhardToneMapping;
      gl.toneMappingExposure = 1.5;
      gl.gammaOutput = true;
      camera.layers.set(0);
      composer.current!.render();

      gl.clearDepth();

      gl.toneMapping = LinearToneMapping;
      gl.toneMappingExposure = 1;
      gl.gammaOutput = false;
      camera.layers.set(2);
      gl.render(scene, camera);
    }
  }, 1);

  return (
    <>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />

        <bloomPass attachArray="passes" args={[0.65, 20, 4, 128]} />

        <shaderPass
          attachArray="passes"
          args={[FXAAShader]}
          uniforms-resolution-value={[1 / size.width, 1 / size.height]}
          renderToScreen
        />
      </effectComposer>
      <React.Suspense fallback={<Done />}>
        <InternetWifi />
        <Entertainment />
        <Lighting />
        <Security />
        <HomeControl />
        <Clouds position={[0, 1, 0]} />
        <ChristmasHouse />
        <ChristmasGround />
        <Bird />
        <MainSnowfall
          particles={20000}
          width={20}
          height={7}
          depth={20}
          enabled={loadingState >= DONE}
        />
      </React.Suspense>
    </>
  );
};
