import React, { forwardRef } from 'react';
import pick from 'lodash/pick';
import { useTexture } from '../../../hooks/use-loaded';
import { Instance } from '../../../types';

export const SpotLight = forwardRef((props: Instance & { active: any }, r) => {
  const alpha = useTexture('alphamap');

  return (
    <group {...pick(props, 'position', 'rotation', 'quaternion')}>
      <mesh ref={r}>
        <sphereBufferGeometry args={[0.0127, 16, 5]} attach="geometry" />
        <meshBasicMaterial
          attach="material"
          alphaMap={alpha}
          alphaTest={0.5}
        />
      </mesh>

    </group>
  );
});
