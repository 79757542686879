import React, { useRef, useEffect, useCallback, useContext, useState } from 'react';

import { a } from 'react-spring/three';
import { Math as THREEMath, PerspectiveCamera } from 'three';
import { useThree, useFrame } from 'react-three-fiber';
import { useSpring, config } from 'react-spring/three';
import { useGesture } from 'react-use-gesture';
import { UIContext, LookAt } from '../../context/UIContext';
import { LoadingContext, DONE, INTERACTIVE } from '../../context/LoadingContext';

import clamp from 'lodash/clamp';
import { isMobile } from '../../utils/is-mobile';

interface Props {
  children: any;
  zoom: number;
  onReady?(): void;
}

export const Camera = ({ children, zoom }: Props) => {
  const {
    size: { width, height },
    aspect,
    viewport,
    setDefaultCamera,
    gl,
  } = useThree();

  const { introPlayed, selectedCategory, dragTip, setDragTip } = useContext(UIContext);
  const [interactive, setInteractive] = useState(false);

  const houseWidth = 9;
  const houseHeight = 2.5;

  const { loadingState, setLoadingState } = useContext(LoadingContext);

  const ref = useRef<PerspectiveCamera>();

  const handleOrientationChange = () => {
    if (introPlayed) {
      runWebGLSpring();
    }
  };

  useEffect(() => {
    if (interactive) {
      setLoadingState(INTERACTIVE);
    }
  }, [interactive]);

  useEffect(() => {
    setDefaultCamera(ref.current!);

    window.addEventListener('deviceorientation', handleOrientationChange);

    return () => window.removeEventListener('deviceorientation', handleOrientationChange);
  }, []);

  const [cameraProps, set, stop] = useSpring(() => ({
    position: [0.15, 110 * 0.0254, 100 * 0.0254],
    rotation: [Math.PI / 8, 0, 0],
    fov: 30, // magic values!
    config: config.slow,
  }));

  const influence = useRef<[number, number]>([0, 0]);
  const drag = useRef<number>(0);

  /**
   * Zoom / camera mechanism
   *
   * Generally, the viewport is controlled by the aspect ratio and the level of zoom.
   * FOV is determined based on the aspect ratio.
   *
   * Z position is either the minimum distance required to view both floors, or the
   * selected % of house width viewable (houseWidth / zoom).
   *
   * X position is either fixed when the entire house is visible, or some variable
   * drag coeffient clamped to half the out-of-viewport width of the house in either direction.
   *
   * Y position is based on the zoom level (with a dash of magic numbers)
   */
  const runWebGLSpring = useCallback(
    (conf?: object, onRest?: () => void) => {
      if (!introPlayed || selectedCategory) {
        return;
      }

      const aspectInverted = 1 / aspect;

      const [inflx, infly] = influence.current;

      const fov = 40 * aspectInverted;

      const farPlane = 2 * Math.tan(THREEMath.degToRad(fov) * 0.5);

      // z
      const fpHeight = houseHeight / farPlane;
      const fpZoomWidth = houseWidth / zoom / (farPlane * aspect);

      const newZ = Math.max(fpHeight, fpZoomWidth);

      // x
      const moveDistance = (houseWidth - newZ * farPlane * aspect) * 0.5;
      drag.current = clamp(drag.current, -moveDistance, moveDistance);
      const newX = 0.15 + drag.current + (2 + inflx) * 0.0254;

      // y
      const newY = (60 / Math.pow(zoom, 0.3) + infly) * 0.0254;

      set({
        position: [newX, newY, newZ],
        rotation: [0, 0, 0],
        fov,
        config: conf || config.slow,
        onRest: onRest || (() => !interactive && setInteractive(true)),
      });
    },
    [set, stop, selectedCategory, introPlayed, aspect, zoom]
  );

  const bind = useGesture(
    {
      onDrag: ({ delta: [cx] }) => {
        if (!interactive) {
          return;
        }

        drag.current += -cx * 0.0154;

        if (zoom > 1 && dragTip) {
          setDragTip(false);
        }

        runWebGLSpring();
      },

      onMove: ({ xy: [x, y], dragging }) => {
        if (!interactive) {
          return;
        }

        influence.current = [(-0.5 + x / width) * 2 * 5, -((-0.5 + y / height) * 2) * 5];

        if (!dragging) {
          runWebGLSpring();
        }
      },
    },

    {
      domTarget: gl.domElement,
    }
  );

  useEffect(runWebGLSpring, [zoom, viewport]);

  useEffect(bind as any, [bind]);

  useEffect(() => {
    // post loader, pre scene camera movement
    if (loadingState >= DONE && !introPlayed) {
      setTimeout(() => {
        set({
          position: [0.15, 110 * 0.0254, -100 * 0.0254],
          rotation: [Math.PI / 8, 0, 0],
          immediate: true,
          onRest: () => {
            set({
              immediate: false,
              position: [0.15, 50 * 0.0254, -100 * 0.0254],
              rotation: [Math.PI / 16, 0, 0],
              fov: 40,
              config: {
                tension: 10,
                friction: 7,
                precision: 0.01,
              },
            });
          },
        });
      }, 500);
      // tween into scene after pressing "áfram"
    } else if (introPlayed) {
      runWebGLSpring({
        mass: 0.5,
        tension: 2,
        friction: 2,
        precision: 0.01,
      });
    }
  }, [loadingState, introPlayed]);

  // camera movement for when you select a product category
  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (selectedCategory) {
      const [
        ,
        {
          position: [x, y, z],
          lookAt,
        },
      ] = selectedCategory;

      let targetPos: LookAt = {
        position: [x + 0.6, y, z + 3.508],
        rotation: [0, 0, 0],
      };

      if (lookAt) {
        targetPos = lookAt;
      }

      set({
        ...targetPos,
        config: config.slow,
      });
    } else if (introPlayed) {
      runWebGLSpring();
    }
  }, [selectedCategory]);

  useFrame(() => {
    if (ref.current) {
      ref.current!.updateMatrixWorld();
    }
  }, 1000);

  return (
    <>
      <a.perspectiveCamera
        ref={ref}
        near={0.1}
        far={500}
        aspect={width / height}
        onUpdate={(self: any) => self.updateProjectionMatrix()}
        {...cameraProps}
      />

      {ref.current && children}
    </>
  );
};
