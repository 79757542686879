import React, { useRef, useMemo, useContext } from 'react';
import { useFrame, useResource } from 'react-three-fiber';
import { Plane, Mesh, CubeTextureLoader, Group } from 'three';
import { LoadingContext } from '../../../context/LoadingContext';

interface DiscoBallProps {
  clipPlane: Plane;
}

export const DiscoBall = ({ clipPlane }: DiscoBallProps) => {
  const ref = useRef<Group>();

  const [ballRef] = useResource<Mesh>();
  const { get } = useContext(LoadingContext);

  const texture = useMemo(() => {
    return new CubeTextureLoader().load(Array.from({ length: 6 }).fill(get(
      'nova_texture'
    )! as string) as string[]);
  }, []);

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.01;
    }
  });

  return (
    <>
      {/* {ball && <spotLight position={[0, 0, 10]} target={ball} />} */}
      <group ref={ref} scale={[0.12, 0.12, 0.12]}>
        <mesh name="disco" castShadow receiveShadow ref={ballRef}>
          <sphereBufferGeometry attach="geometry" args={[1, 40, 24]} />
          <meshStandardMaterial
            attach="material"
            flatShading
            clipIntersection={true}
            clippingPlanes={[clipPlane]}
            clipShadows={true}
            color="white"
            roughness={0.1}
            envMapIntensity={1.5}
            envMap={texture}
            metalness={0.6}
          />
        </mesh>
      </group>
    </>
  );
};
