import { useEffect, useMemo, useRef } from 'react';
import { Object3D, Mesh, MeshStandardMaterial, Texture } from 'three';
import set from 'lodash/set';
import { useTexture, prune } from './use-loaded';
import { useDayNight } from './use-day-night';
import { MixMaterial } from '../components/scene/materials/MixMaterial';
import { useSpring } from 'react-spring';
import { copyTextureProps } from '../utils/copy-props';

export function useNightTextures(scene: Object3D, ids: string[], objectNames: string[] = []) {
  const textures = useTexture<Texture[]>(ids);

  const texByName = useMemo<{ [key: string]: Texture }>(
    () =>
      textures.reduce((obj, t, i) => {
        let name = t.name;

        if (objectNames.length >= i && objectNames[i]) {
          name = objectNames[i];
        }

        return set(obj, name, t);
      }, {}),
    [textures]
  );

  const nightLevel = useDayNight();

  const texturables = useRef<MixMaterial[]>([]);

  useSpring({
    night: nightLevel,
    onFrame(val: any) {
      texturables.current.forEach((obj: any) => {
        const mixMat = obj as MixMaterial;

        if (mixMat.mixFactor) {
          mixMat.mixFactor.value = val.night;
        }
        mixMat.needsUpdate = true;
      });
    },
    onRest() {
      texturables.current.forEach((obj: any) => {
        const mixMat = obj as MixMaterial;

        mixMat.needsUpdate = false;
      });
    },
  });

  useEffect(() => {
    if (!scene) {
      return;
    }

    const shadowMeshes = ['stairs_window', 'building_frame', 'walls', 'garage_hallway'];
    scene.traverse((obj: any) => {
      if (obj instanceof Mesh) {
        obj.castShadow = shadowMeshes.includes(obj.name);
        obj.receiveShadow = shadowMeshes.includes(obj.name);

        if (obj.material instanceof MeshStandardMaterial) {
          const dayTexture = obj.material.map;

          const nightTexture = texByName[`${obj.name}_night_texture`] || texByName[obj.name];

          if (dayTexture && nightTexture) {
            copyTextureProps(dayTexture, nightTexture);

            const mat = new MixMaterial({
              ...(prune(obj.material) as any),
              otherMap: nightTexture,
              mixFactor: 0.0,
            });

            obj.material = mat;
            texturables.current!.push(mat);
            
          } else {
            console.log('MISSING NIGHT TEXTURE', obj);
          }
        }
      }
    });
  }, [scene]);
}
