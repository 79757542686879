import React from 'react';
import { LeftArrowButton } from '../button/Button';
import styled from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';

interface ModalHeaderProps {
  title: string;
  icon: React.ReactNode;
  onClose?(): void;
}

const ModalHeaderWrapper = styled.header`
  display: flex;

  flex-flow: row nowrap;

  justify-content: space-between;
  align-items: center;

  margin-bottom: 30px;

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 22px;
  }
`;

const Icon = styled.span`
  vertical-align: sub;

  svg path,
  svg rect,
  svg circle {
    stroke: ${variables.colors.font};
  }
`;

const Text = styled.span`
  padding-left: 15px;

  font-weight: bold;
  font-size: 15px;

  line-height: 18px;
`;

const Kicker = styled.div`
  white-space: nowrap;
`

const HeaderButton = styled(LeftArrowButton)`
  font-size: 15px;
  font-weight: 500;

  &:hover {
    background: #fff;
    color: ${variables.colors.red};
  }
`;

export const ModalHeader: React.FC<ModalHeaderProps> = ({ title, icon, onClose }) => {
  return (
    <ModalHeaderWrapper>
      <Kicker>
        <Icon>{icon}</Icon>

        <Text>{title}</Text>
      </Kicker>
      <HeaderButton size="medium" color="white" width="condensed" onClick={onClose}>Til baka</HeaderButton>
    </ModalHeaderWrapper>
  );
};
