import React from 'react';
import { useThree } from 'react-three-fiber';
import { a } from 'react-spring/three';

import { Radial } from '../shaders/Radial';

export const Sky = (props: any) => {
  const { size } = useThree();

  return (
    <mesh position={[0, 0, -350]}>
      <planeBufferGeometry args={[size.width * 200, size.height * 200]} attach="geometry" />
      <a.shaderMaterial
        args={[Radial]}
        uniforms-resolution-value={[size.width, size.height]}
        uniforms-factor-value={props.x}
        attach="material"
      />
    </mesh>
  );
};
