import fragmentShader from 'raw-loader!./fragment/radial.glsl';
import vertexShader from 'raw-loader!./vertex/identity.glsl';
import { Vector3 } from 'three';

export const Radial = {
  uniforms: {
    resolution: {
      value: null,
    },
    fromColor: {
      value: new Vector3(81 / 255, 70 / 255, 108 / 255),
      // value: new Vector3(210 / 255, 190 / 255, 154 / 255),
    },
    toColor: {
      value: new Vector3(21 / 255, 15 / 255, 34 / 255),
    },
    factor: {
      value: 1.0,
    },
  },
  fragmentShader,
  vertexShader,
};
