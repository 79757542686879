import React from 'react';
import { useModel } from '../../../hooks/use-loaded';
import { useNightTextures } from '../../../hooks/use-night-textures';


export const ChristmasHouse = () => {
  const model = useModel('house');

  useNightTextures(model.scene, [
    'garage_hallway_night_texture',
    'bedroom_top_left_night_texture',
    'bedroom_night_texture',
    'building_frame_night_texture',
    'dining_room_night_texture',
    'doors_night_texture',
    'floor_night_texture',
    'garage_entrance_door_night_texture',
    'kitchen_night_texture',
    'LivingRoomFurniture_night_texture',
    'ShoesJacketCandle_night_texture',
    'LivingRoomTree_night_texture',
    'ToysWreath_night_texture',
    'Snow_night_texture',
    'Roof_night_texture',
    'Bulbs_night_texture',
    'stairs_window_night_texture',
    'walls_night_texture',
    'Candle_night_texture'
  ], ['Hallway']);

  return <primitive object={model.scene} scale={[0.0254, 0.0254, 0.0254]} />;
};
