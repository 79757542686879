import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { variables, breakpoints } from '../../styles/variables';
import { rgba } from 'polished';

interface TabProps {
  title: string;
  children: React.ReactNode;
}

interface TabsProps {
  items: TabProps[];
}

const TabsContainer = styled.div``;

interface PanelProps {
  active: boolean;
}

const TabsList = styled.ul`
  display: flex;
  flex-flow: row nowrap;
`;

const activeStyle = css`
  &:before {
    width: 100%;
  }
`;

const TabsListItem = styled.li<PanelProps>`
  position: relative;

  cursor: pointer;

  color: ${rgba(variables.colors.font, 0.5)};

  padding: 10px 0;

  margin-left: 50px;

  &:first-child {
    margin-left: 0;
  }

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 0;
    height: 2px;
    width: 0%;

    background: ${variables.colors.red};

    transition: width 200ms ease-out;

    will-change: width;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 15px 0;
  }

  &:hover {
    ${activeStyle}
  }

  ${props =>
    props.active &&
    css`
      color: ${variables.colors.font};

      ${activeStyle}
    `}
`;

const TabsPanelContainer = styled.div`
  position: relative;
`;

// TODO: make better
const TabsPanel = styled.div<PanelProps>`
  display: none;

  ${props =>
    props.active &&
    css`
      display: block;
    `}
`;

export const Tabs: React.FC<TabsProps> = ({ items }) => {
  const [active, set] = useState(0);

  return (
    <TabsContainer>
      <TabsList>
        {items.map((item, i) => (
          <TabsListItem key={item.title} active={active === i} onClick={() => set(i)} role="button">
            {item.title}
          </TabsListItem>
        ))}
      </TabsList>

      <TabsPanelContainer>
        {items.map((t, i) => (
          <TabsPanel key={`panel-${i}-${t.title}`} active={active === i}>
            {t.children}
          </TabsPanel>
        ))}
      </TabsPanelContainer>
    </TabsContainer>
  );
};
