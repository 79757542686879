import React, { useContext } from 'react';
import { Tooltip as TooltipProps, UIContext } from '../../context/UIContext';
import styled from 'styled-components';
import omit from 'lodash/omit';
import { variables } from '../../styles/variables';
import { useTransition, animated } from 'react-spring';

const TooltipBox = styled(animated.div)<Omit<TooltipProps, 'title'>>`
  position: fixed;
  z-index: 3;

  top: ${props => `${props.y}px`};
  left: ${props => `${props.x}px`};
  transform: translateX(25%);

  pointer-events: none;
`;

const Bubble = styled.div`
  position: relative;
  font-size: 13px;
  padding: 12px 21.5px;

  color: white;
  font-weight: 600;
  font-family: ${variables.font.familyHeading};

  white-space: nowrap;

  border-radius: 18px;
  z-index: 1;
  background: ${variables.colors.red};
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.5));

  &:after {
    position: absolute;
    content: '';
    top: calc(100% - 4px);

    left: 50%;

    width: 8px;
    height: 8px;

    background: ${variables.colors.red};
    z-index: 0;
    transform: translateX(-50%) rotate(45deg);
  }
`;

export const Tooltip = ({ tooltip }: { tooltip: TooltipProps | null }) => {
  const transitions = useTransition(tooltip, null, {
    from: { opacity: 0, transform: 'translate3d(-50%, -45px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(-50%, -30px, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-50%, 0px, 0)' },
  });

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <TooltipBox {...omit(item, 'title')} key={key} style={props}>
              <Bubble>{item.title}</Bubble>
            </TooltipBox>
          )
      )}
    </>
  );
};

export const TooltipContainer = () => {
  const { tooltip } = useContext(UIContext);

  return <Tooltip tooltip={tooltip} />;
};
