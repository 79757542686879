import React, { useContext, useMemo } from 'react';
import { UIContext } from '../../context/UIContext';
import { useSpring, a } from 'react-spring/three';
import { Plane, Vector3 } from 'three';
import { DiscoBall } from './objects/DiscoBall';
import { DeviceRenderer } from './objects/DeviceRenderer';

export const Entertainment = () => {
  const ctx = useContext(UIContext);
  const { x } = useSpring({
    x: Number(ctx.activeSection === 'entertainment'),
    config: { tension: 140, friction: 40 },
  });

  const plane = useMemo(() => new Plane(new Vector3(0, -1, 0), 0.9), []);

  const disco = x.interpolate((v: number) => [0.9017, 1.04 - v * 0.3556, 0]);

  return (
    <>
      <a.group position={disco}>
        <DiscoBall clipPlane={plane} />
      </a.group>

      <DeviceRenderer section="entertainment" />
    </>
  );
};
