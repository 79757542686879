import React, { useContext } from 'react';
import { Modal } from './Modal';
import { Container } from '../container/Container';
import styled from 'styled-components';
import { responsiveFont } from '../../styles/utils';
import { variables, breakpoints } from '../../styles/variables';

import { UIContext } from '../../context/UIContext';
import { DataContext } from '../../context/DataContext';

import { ProductItem } from '../product-item/ProductItem';
import { Carousel } from '../carousel/Carousel';
import { Headline } from '../headline/Headline';

interface BundleModalProps {
  onClose?(): void;
}

const Subheading = styled.h3`
  ${responsiveFont(24, 32)};

  margin-bottom: 20px;

  font-family: ${variables.font.familyHeading};
  font-weight: 800;
  line-height: ${74 / 70};

  color: #232323;
  letter-spacing: 0;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
`;

const Description = styled.p`
  ${responsiveFont(14, 18)};
  line-height: ${30 / 18};

  margin-top: 20px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 40px;
  }
`;

const Bundle = styled.div`
  padding: 60px 0 80px;

  @media (min-width: ${breakpoints.lg}) {
    padding: 80px 0 60px;
  }
`;

export const BundleModal: React.FC<BundleModalProps> = () => {
  const { activeBundle } = useContext(UIContext);

  const { sectionBundles } = useContext(DataContext);

  return (
    <Modal visible={activeBundle}>
      <Container>
        <Headline text="Vá! Þú ert allur pakkinn." />

        <Description>
          Hér eru pakkar fyrir fólk sem er tilbúið að taka skref inn í framtíðina, vera snjallari en allir hinir og spara pening í leiðinni!
        </Description>

        {Object.entries(sectionBundles)
          .filter(([, p]) => p.length)
          .map(([title, products]) => (
            <Bundle key={title}>
              <Subheading>{title}</Subheading>
              <Carousel isLarge>
                {products.map(p => (
                  <ProductItem
                    key={p.id}
                    kicker={p.vendor}
                    heading={p.title}
                    text={p.description}
                    image={p.photo}
                    price={p.price}
                    link={p.link}
                  />
                ))}
              </Carousel>
            </Bundle>
          ))}
      </Container>
    </Modal>
  );
};
