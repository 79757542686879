import React, { forwardRef } from 'react';
import pick from 'lodash/pick';
import { Instance } from '../../../types';
import { useTexture } from '../../../hooks/use-loaded';

export const Scooter = forwardRef((props: Instance & { active: any }, r) => {
  const alpha = useTexture('alphamap');

  return (
    <group {...pick(props, 'position', 'rotation', 'quaternion')} ref={r}>
      {/* lower part */}
      <mesh position-x={0} position-y={-0.2} position-z={-0.4}>
        <boxBufferGeometry args={[0.4, 0.1, 0.31]} attach="geometry" />
        <meshBasicMaterial attach="material" alphaMap={alpha} alphaTest={0.5} />
      </mesh>
      {/* bar */}
      <mesh position-x={0.15} position-y={-0.1} position-z={-0.4}>
        <boxBufferGeometry args={[0.08, 0.32, 0.15]} attach="geometry" />
        <meshBasicMaterial attach="material" alphaMap={alpha} alphaTest={0.5} />
      </mesh>
    </group>
  );
});
