import React, { useRef, useState, useEffect, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useChain, useSpring, config, animated } from 'react-spring';

import { responsiveFont } from '../../styles/utils';
import { variables } from '../../styles/variables';

interface BlockTextProps {
  percentage: number;
  animateInLoader?: boolean;
  onAnimationOutLoader?: () => void;
  onProgressVisible?: () => void;
  isPreloadCompleted?: boolean;
}

const LoaderRoot = styled.div`
  position: relative;
  margin: 40px 0 60px;
`;

const ProgressWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
`;

const ProgressBar = styled(animated.div)`
  position: relative;
  height: 16px;
  right: 0;
  left: auto;

  margin-right: 0;
  margin-left: auto;

  opacity: 0;

  background: #fff;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.2);
  border-radius: 9px;
  overflow: hidden;
`;

const ProgressFill = styled(animated.div)`
  position: absolute;
  z-index: 1;

  height: 100%;
  width: 0%;

  background: ${variables.colors.red};
`;

const IndicatorWrap = styled(animated.div)`
  position: absolute;
  z-index: 1;

  left: 0%;
  top: 35px;
`;

const Indicator = styled(animated.div)`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${variables.colors.red};
  color: #fff;
  height: 60px;
  width: 60px;
  margin-left: -30px;

  font-weight: 500;
  font-size: 20px;
  text-align: center;

  transform: scale(0);
  transform-origin: 'top center';

  border-radius: 50%;
`;

const Count = styled(animated.div)`
  margin-top: 5px;
  opacity: 0;
  transform: translateY(50%);

  font-weight: 600;
  font-family: ${variables.font.familyHeading};
  font-variant-numeric: tabular-nums;
  line-height: 1;
`;

// needs to be separate because of intro animation
const Arrow = styled.div`
  position: absolute;
  top: 1px;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: 99%;
    left: 50%;

    height: 0;
    width: 0;

    margin-left: -6px;

    pointer-events: none;

    border: solid transparent;
    border-color: rgba(${variables.colors.red}, 0);
    border-bottom-color: ${variables.colors.red};
    border-width: 6px;
  }
`;

export function Loader({
  percentage,
  isPreloadCompleted,
  animateInLoader,
  onAnimationOutLoader,
  onProgressVisible,
}: any) {
  const refIndicator = useRef(null);
  const refCount = useRef(null);

  const [barProps, setBarProps] = useSpring(() => ({
    opacity: 0,
    transform: 'scale(1)',
    width: '100%',
    config: { mass: 0.6, friction: 70 },
  }));

  const [indicatorProps, setIndicatorProps] = useSpring(() => ({
    transform: 'scale(0)',
    config: { mass: 0.3, friction: 25, tension: 180 },
  }));

  const [countProps, setCountProps] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(80%)',
    config: { mass: 0.1, friction: 22 },
  }));

  const [progressProps, setProgress] = useSpring(() => ({
    width: 0,
    config: { mass: 0.2, friction: 30 },
  }));

  const [indicatorPositionProps, setIndicatorPositionProps] = useSpring(() => ({
    left: 0,
    config: { mass: 0.2, friction: 30 },
  }));

  const [shouldRunOutro, setShouldRunOutro] = useState(false);

  const displayProgress = useCallback(() => {
    if (!isPreloadCompleted) {
      onProgressVisible();
    }
  }, [isPreloadCompleted]);

  const runIntro = () => {
    setBarProps({
      opacity: 1,
    });

    setIndicatorProps({
      transform: 'scale(1)',
      delay: 300,
      onRest: displayProgress,
    });

    setCountProps({
      transform: 'translateY(0%)',
      opacity: 1,
      delay: 500,
    });
  };

  const runOutro = () => {
    setCountProps({
      transform: 'translateY(80%)',
      opacity: 0,
    });

    setIndicatorProps({
      transform: 'scale(0)',
      delay: 500,
    });

    setBarProps({
      width: '2%',
      onRest: () => scaleBarOut(),
      delay: 200,
      config: { mass: 0.2, friction: 20, precision: 0.01 },
    });
  };

  const scaleBarOut = () => {
    onAnimationOutLoader();
    setBarProps({
      transform: 'scale(0)',
      config: { mass: 0.2, friction: 30 },
    });
  };

  useEffect(() => {
    if (shouldRunOutro && isPreloadCompleted) {
      runOutro();
    }
  }, [shouldRunOutro, isPreloadCompleted]);

  // progress changes
  useEffect(() => {
    setProgress({
      width: percentage,
      onRest: () => setShouldRunOutro(percentage >= 100),
      config: {
        precision: 0.01,
      },
    });
    setIndicatorPositionProps({
      left: percentage,
    });
  }, [percentage]);

  // run 'intro' loader animation
  useEffect(() => {
    if (animateInLoader) {
      runIntro();
    }
  }, [animateInLoader]);

  return (
    <LoaderRoot>
      <ProgressWrap>
        <ProgressBar
          style={{
            ...barProps,
          }}
        >
          <ProgressFill
            style={{
              ...progressProps,
              width: progressProps.width.interpolate(width => `${width}%`),
            }}
          />
        </ProgressBar>
        <IndicatorWrap
          style={{
            ...indicatorPositionProps,
            left: indicatorPositionProps.left.interpolate(left => `${left}%`),
          }}
        >
          <Indicator
            ref={refIndicator}
            style={{
              ...indicatorProps,
            }}
          >
            <Arrow />

            <Count ref={refCount} style={countProps}>
              {percentage >= 100 ? 100 : percentage}
            </Count>
          </Indicator>
        </IndicatorWrap>
      </ProgressWrap>
    </LoaderRoot>
  );
}
