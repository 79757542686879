import React, { useContext, forwardRef } from 'react';
import { useSectionCategories } from '../../../hooks/use-section-categories';
import { UIContext } from '../../../context/UIContext';
import { useSpring } from 'react-spring/three';

import { SelectObject } from './SelectObject';
import { SecurityCam } from './SecurityCam';
import { Doorbell } from './Doorbell';
import { Thermo } from './Thermo';
import { Router } from './Router';
import { Extender } from './Extender';
import { SmokeDetector } from './SmokeDetector';
import { Category, Instance } from '../../../types';
import flatMap from 'lodash/flatMap';
import { Speaker } from './Speaker';
import { Hub } from './Hub';
import { Sensor } from './Sensor';
import { SpotLight } from './SpotLight';
import { LightBar } from './LightBar';
import { Bulb } from './Bulb';
import { Tv } from './Tv';
import { Soundbar } from './Soundbar';
import { Scooter } from './Scooter';
import { Antenna } from './Antenna';
import { useTexture } from '../../../hooks/use-loaded';

export interface DeviceRendererProps {
  section: string;
  children?(section: string, active: any): React.ReactNode;
}

const DeviceMap: { [key: string]: any } = {
  'security-cam': SecurityCam,
  'security-cam-outdoor': SecurityCam,
  doorbell: Doorbell,
  thermo: Thermo,
  router: Router,
  extender: Extender,
  'smoke-detector': SmokeDetector,
  speaker: Speaker,
  'voice-control': Speaker,
  hub: Hub,
  sensor: Sensor,
  spotlight: SpotLight,
  bulb: Bulb,
  'light-bar': LightBar,
  tv: Tv,
  soundbar: Soundbar,
  scooter: Scooter,
  antenna: Antenna,
};

const Dummy = forwardRef((props: any, r) => {
  const tex = useTexture('alphamap'); 
  return (<mesh {...props} ref={r}>
    <boxGeometry args={[0.1, 0.1, 0.1]} attach="geometry" />
    <meshBasicMaterial attach="material" alphaMap={tex} alphaTest={0.5} />
  </mesh>)
});

export const DeviceRenderer = ({ section, children }: DeviceRendererProps) => {
  const { activeSection } = useContext(UIContext);
  const cats = useSectionCategories(section);

  const { x } = useSpring({
    x: Number(activeSection === section),
    config: { tension: 140, friction: 40 },
  });

  const renderObject = (category: Category, instance: Instance, Obj: any) => {
    if (!Obj) {
      Obj = Dummy;
    }
    const key = `${category.id}-${instance.position.join('')}`;

    const result = <SelectObject key={key} {...category} object={Obj} active={x} instance={instance} />;

    return result;
  };

  return (
    <>
      {flatMap(cats, cat => cat.instances.map(instance => renderObject(cat, instance, DeviceMap[cat.id])))}

      {children && children(section, x)}
    </>
  );
};
