import { lighten, math, shade } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { variables } from '../../styles/variables';
import ArrowRightSvg from '../../assets/svg/arrow-right.svg';
import ArrowLeftSvg from '../../assets/svg/arrow-left.svg';
import { FlexDirectionProperty } from 'csstype';

type Sizes = 'big' | 'medium';

interface ButtonProps {
  href?: string;
  disabled?: boolean;
  visible?: boolean;
  size?: Sizes;

  active?: boolean;
  color?: 'red' | 'white';
  children: React.ReactNode;
  [key: string]: any;
  night?: boolean;
}

interface WrapperProps {
  size: Sizes;
  transparent?: boolean;
}

const settings = {
  width: {
    full: {
      padding: '0 40px',
    },
    condensed: {
      padding: '0px 30px',
    },
  },
  sizes: {
    big: '76px',
    medium: '60px',
  },
  colors: {
    red: {
      backgroundColor: variables.colors.red,
      color: '#fff',
      boxShadow: 'inset 0 -2px 0 0 rgba(0, 0, 0, 0.25)',
    },
    white: {
      backgroundColor: '#fff',
      color: variables.colors.font,
      boxShadow: '0 6px 10px 0 rgba(0,0,0,0.08)',
    },
  },
};

const Wrapper = styled.div<WrapperProps>`
  position: relative;

  display: inline-flex;

  justify-items: space-evenly;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    pointer-events: none;

    border-radius: ${props => math(`${settings.sizes[props.size!]} / 2px`)};
    background: url(${require('../../assets/images/dots.svg')}) repeat-x;
    transform: translate3d(0, 10px, 0);
    z-index: 0;
  }

  ${props =>
    props.transparent &&
    css`
      &:after {
        opacity: 0;
        transition: opacity 180ms ease-out 45ms;
      }
    `}
`;

const Text = styled.span`
  white-space: nowrap;
`;

const Arrow = styled.span`
  height: 100%;
  display: inline-flex;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

function ButtonElement(props: ButtonProps) {
  const { children, transparent, arrowIcon, size, active, ...passProps } = props;

  const s = size || 'medium';

  const isLink = typeof props.href !== 'undefined';
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(props.href || '');

  if (isExternal) {
    return (
      <Wrapper size={s}>
        <a target="_blank" rel="noopener noreferrer" {...passProps}>
          <Text>{children}</Text>
        </a>
      </Wrapper>
    );
  }

  if (isLink) {
    return (
      <Link {...passProps} to={props.href || '#'}>
        <Text>{children}</Text>
      </Link>
    );
  }

  return (
    <Wrapper size={s} transparent={!active && transparent}>
      <button {...passProps}>{children}</button>
    </Wrapper>
  );
}

export const Button = styled(ButtonElement)`
  position: relative;
  display: inline-flex;
  align-items: center;

  height: ${props => settings.sizes[props.size!]};
  line-height: 1;

  border-radius: ${props => math(`${settings.sizes[props.size!]} / 2px`)};

  ${props => css`
    background: ${settings.colors[props.color!].backgroundColor};
    color: ${settings.colors[props.color!].color};
    box-shadow: ${settings.colors[props.color!].boxShadow};

    padding: ${settings.width[props.width!].padding};
  `}

  font-family: ${variables.font.familyHeading};
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;

  z-index: 1;
  cursor: pointer;

  transition: 180ms;
  transition-property: border-color, background-color, color, opacity;

  &:hover,
  &:focus {
    outline: none;
  }

  &:hover {
    background-color: #fff;
    color: ${variables.colors.red};
  }
`;

Button.defaultProps = {
  size: 'medium',
  color: 'red',
  width: 'condensed',
};

interface ButtonWithArrowProps {
  direction: FlexDirectionProperty;
  width?: 'full' | 'condensed';
}

const ButtonWithArrow = styled(Button)<ButtonWithArrowProps>`
  ${props => css`
    flex-direction: ${props.direction};
  `}

  ${Text} {
    ${props => {
      const combo = `${props.width}-${props.direction}`;

      if (combo === 'full-row') {
        return css`
          padding: 0 120px 0 30px;
        `;
      }

      if (combo === 'full-row-reverse') {
        return css`
          padding: 0 30px 0 120px;
        `;
      }

      return css`
        padding-${props.direction === 'row' ? 'right' : 'left'}: 15px;
      `;
    }}
  }
`;

interface IconButtonProps extends ButtonProps {
  icon: React.ReactNode;

  transparent?: boolean;
}

const activeButtonStyles = css`
  background-color: ${variables.colors.red};

  color: #fff;

  svg path,
  svg rect,
  svg circle {
    stroke: #fff;
  }
`;

const activeButton = css`
  ${activeButtonStyles}

  &:hover {
    ${activeButtonStyles};
  }
`;

const ButtonWithIcon = styled(Button)<IconButtonProps>`
  position: relative;
  background: transparent;

  color: ${variables.colors.font};
  background: transparent;
  box-shadow: none;

  svg path,
  svg rect,
  svg circle {
    stroke: ${variables.colors.font};
    transition: stroke 180ms ease-in-out;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 30px;
    right: 30px;
    height: 2px;
    width: 0%;

    background: ${variables.colors.red};

    transition: width 200ms ease-out, opacity 200ms ease-out;

    will-change: width;
  }

  &:hover {
    color: ${variables.colors.font};
    background: transparent;
    svg path,
    svg rect,
    svg circle {
      stroke: ${variables.colors.font};
    }

    &:before {
      width: calc(100% - 60px);
      background: ${variables.colors.red};
    }
  }

  ${props => props.active && activeButton}

  ${props =>
    props.night &&
    css`
      color: #fff;

      svg path,
      svg rect,
      svg circle {
        stroke: #fff;
      }

      &:before {
        background: #fff;
      }

      &:hover {
        color: #fff;
        svg path,
        svg rect,
        svg circle {
          stroke: #fff;
        }

        &:before {
          width: calc(100% - 60px);
          background: #fff;
        }
      }
    `}

    ${props =>
      props.active &&
      css`
        &:before {
          opacity: 0;
        }
      `}
`;

const IconContainer = styled.span`
  padding-right: 15px;
`;

interface ArrowButtonProps extends ButtonProps {
  arrowIcon: React.ReactNode;
  direction: FlexDirectionProperty;
}

const ArrowButton: React.FC<ArrowButtonProps> = props => (
  <ButtonWithArrow {...props}>
    <Text>{props.children}</Text>

    <Arrow>{props.arrowIcon}</Arrow>
  </ButtonWithArrow>
);

export const IconButton: React.FC<IconButtonProps> = props => {
  const { icon, children, ...passProps } = props;
  return (
    <ButtonWithIcon {...passProps} transparent>
      <IconContainer>{icon}</IconContainer>
      <span>{children}</span>
    </ButtonWithIcon>
  );
};

export const RightArrowButton: React.FC<ButtonProps> = props => {
  return <ArrowButton arrowIcon={<ArrowRightSvg />} direction="row" {...props} />;
};

export const LeftArrowButton: React.FC<ButtonProps> = props => {
  return <ArrowButton arrowIcon={<ArrowLeftSvg />} direction="row-reverse" {...props} />;
};
