import { useContext, useMemo } from 'react';
import { UIContext } from '../context/UIContext';

export function useDayNight() {
  const { activeSection } = useContext(UIContext);

  const nightLevel = useMemo(() => {
    if (activeSection === 'security') {
      return 0.5;
    } else if (activeSection === 'lighting') {
      return 1;
    }
    return 0;
  }, [activeSection]);

  return nightLevel;
}
