import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  AnimationMixer,
  AnimationAction,
  LoopRepeat,
  LoopOnce,
  AnimationClip,
  VectorKeyframeTrack,
  NumberKeyframeTrack,
} from 'three';
import { useModel } from '../../../hooks/use-loaded';

import { useFrame } from 'react-three-fiber';

interface RobberProps {
  active: boolean;
  position: [number, number, number];
}

if (console.warn) {
  console.warn = () => {};
}

export const Robber = ({ active, position }: RobberProps) => {
  const model = useModel('robber');
  const ref = useRef();
  const target = useRef();
  const lightRef = useRef();

  const timeoutId = useRef<NodeJS.Timeout>();

  const [mixer] = useState(new AnimationMixer(ref.current));

  const actions = useRef<{ [key: string]: AnimationAction }>({});

  const play = useCallback(() => {
    if (!active) {
      return;
    }

    const { rob, light } = actions.current;

    rob.stop();
    light.stop();

    light
      .syncWith(rob)
      .setLoop(LoopOnce, 1)
      .play();

    rob.setLoop(LoopOnce, 1).play();

    timeoutId.current = setTimeout(play, 15000);
  }, [actions.current, active]);

  useEffect(() => {
    lightRef.current.target = target.current;

    // @prettier-ignore
    const lightPath = [
      0.24,
      0.2,
      0.1,

      0.24,
      0.2,
      0.1,

      -0.04,
      0.3,
      1,

      -0.44,
      0.17,
      0.2,

      -0.44,
      0.17,
      0.2,

      -0.04,
      0.3,
      1,

      0.24,
      0.2,
      0.1,
    ];

    // @prettier-ignore
    const onOff = [0, 0, 10, 10, 0, 0];

    // @prettier-ignore
    const dist = [0, 0.0001, 0.7, 0.7, 1.5, 0.7, 0.7, 1.5, 0.7, 0.7, 0.0001];

    const targetClip = new AnimationClip('target', 6.666666507720947, [
      new VectorKeyframeTrack('Target.position', [0, 2.5, 2.85, 3.2, 3.6, 4.05, 4.5], lightPath),
      new NumberKeyframeTrack('.intensity', [0, 2.1, 2.2, 4.7, 4.8, 6.66666], onOff),
      new NumberKeyframeTrack('.distance', [0, 2.1, 2.2, 2.5, 2.85, 3.2, 3.6, 4.05, 4.5, 4.9, 4.91], dist),
    ]);

    actions.current = {
      light: mixer.clipAction(targetClip, lightRef.current),
      rob: mixer.clipAction(model.animations[0], ref.current),
    };

    return () => {
      mixer.uncacheClip(model.animations[0]);
      mixer.uncacheClip(targetClip);
      clearTimeout(timeoutId.current);
    };
  }, []);

  useFrame((_, delta) => {
    mixer.update(delta);
  }, 200);

  useEffect(() => {
    if (!actions.current.rob) {
      return;
    }

    if (active) {
      play();
    } else {
      const { rob, light } = actions.current;
      light.fadeOut(0.4);
      rob.fadeOut(0.4);
      clearTimeout(timeoutId.current);
    }
  }, [active]);

  return (
    <>
      <group position={position} {...model.__$[0]} scale={[0.0254, 0.0254, 0.0254]}>
        <group ref={ref}>
          <primitive object={model.scene} />
        </group>
      </group>
      <spotLight
        name="Light"
        castShadow
        ref={lightRef}
        position={[-0.2, 1.21, -0.45]}
        angle={Math.PI / 2.5}
        penumbra={0.5}
        decay={2}
        intensity={0}
        distance={0.7}
        shadow-mapSize-height={2048}
        shadow-mapSize-width={2048}
        shadow-camera-near={0.1}
        shadow-camera-far={100}
      >
        {/* <mesh>
          <sphereGeometry args={[0.05, 5]} attach="geometry" />
          <meshBasicMaterial attach="material" />
        </mesh> */}
        <object3D name="Target" ref={target} />
      </spotLight>
    </>
  );
};
