import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { animated, useTrail, config } from 'react-spring';
import { Container } from '../container/Container';
import { Row, Column } from '../grid';
import { IconButton } from '../button/Button';
import { Section } from '../../types';
import { Tooltip } from './Tooltip';
import { UIContext } from '../../context/UIContext';
import { breakpoints, variables } from '../../styles/variables';

const SelectorContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.lg}) {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const SectionBar = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 0;

  margin: 0 200px 0 220px;
`;

interface SectionProps {
  selected?: boolean;
}

const SectionButton = styled(IconButton)`
  font-size: 15px;
  font-weight: 600;
`;

const SectionItem = styled(animated.li)<SectionProps>`
  height: 60px;
  line-height: 60px;

  &:last-child {
    position: absolute;
    right: -30px;
    margin: 0;
  }

  @media (min-width: 1280px) {
    margin: 0 10px;
  }

  @media (min-width: 1440px) {
    margin: 0 15px;
  }
`;

interface SectionSelectorProps {
  sections: Section[];
  activeSection: string | null;
  onSectionSelect(section: string): void;
}

export const SectionSelector = ({ sections, activeSection, onSectionSelect }: SectionSelectorProps) => {
  const { introPlayed } = useContext(UIContext);

  const defaultStyle = {
    config: { mass: 0.35, friction: 30 },
    opacity: 0,
    y: -80,
  };

  const inViewStyle = {
    config: { mass: 0.35, friction: 30 },
    opacity: 1,
    y: 0,
    delay: 2400,
  };

  const [listTrail, setListProps] = useTrail(sections.length, () => defaultStyle);

  useEffect(() => {
    if (introPlayed) {
      sections.forEach((section, i) => {
        setListProps(inViewStyle);
      });
    }
  }, [introPlayed]);

  return (
    <SelectorContainer>
      <SectionBar>
      {listTrail.map(({ y, ...rest }, index) => (
        <SectionItem
          key={`section-${index}`}
          style={{
            ...rest,
            transform: y.interpolate((y) => `translate3d(0,${y}px,0)`),
          }}
        >
          <SectionButton
            night={activeSection !== 'bundles'}
            active={activeSection === sections[index].id}
            onClick={() => onSectionSelect(sections[index].id)}
            icon={sections[index].icon}
          >
            {sections[index].title}
          </SectionButton>
        </SectionItem>
      ))}
      </SectionBar>
    </SelectorContainer>
  );
};
