import React, { useContext, useState, useEffect } from 'react';
import { Object3D, Group, Box3, Vector3 } from 'three';
import { addV } from 'react-use-gesture';
import { useResource } from 'react-three-fiber';
import omit from 'lodash/omit';
import isString from 'lodash/isString';

import { Bubble } from './Bubble';
import { UIContext } from '../../../context/UIContext';
import { LoadingContext, POST_RENDER_CONFIG } from '../../../context/LoadingContext';
import { Category, Instance } from '../../../types';
import { usePrevious } from '../../../hooks/use-previous';
import { useTexture } from '../../../hooks/use-loaded';
import { isMobile } from '../../../utils/is-mobile';

interface SelectObjectProps extends Category {
  object: any; // react cmp
  instance: Instance;
  active: any;
}

const TOOLTIP_SIZE = 0.14;

export const SelectObject = ({
  object: Cmp,
  instance,
  active,
  section_id,
  id,
  title,
  icon,
}: SelectObjectProps) => {
  const { activeSection, setSelectedCategory, setActiveSection, selectedCategory } = useContext(UIContext);
  const { loadingState } = useContext(LoadingContext);

  const [ref, root] = useResource<Group>();

  const previousActiveSection = usePrevious(activeSection);
  const alpha = useTexture('alphamap');
  const [hitbox, setHitbox] = useState();

  const computeBoundingBox = () => {
    let target: Object3D = root;

    root.traverse(obj => {
      if (obj.userData.measure) {
        target = obj;
      }
    });

    const box = new Box3().setFromObject(target);

    // this could be 0 if the model is still loading
    const boxSize = box.getSize(new Vector3());

    const center = box.getCenter(new Vector3()).toArray();

    let tooltip;
    if (instance.tooltip === 'left') {
      tooltip = addV(center, [-boxSize.x / 2 - TOOLTIP_SIZE, 0, 0]);
    } else if (instance.tooltip === 'below') {
      tooltip = addV(center, [0, -boxSize.y / 2 - TOOLTIP_SIZE, 0]);
    } else {
      tooltip = addV(center, [0, boxSize.y / 2 + TOOLTIP_SIZE, 0]);
    }

    setHitbox({
      position: center,
      scale: boxSize,
      tooltip,
    });
  };

  useEffect(() => {
    if (loadingState >= POST_RENDER_CONFIG && root) {
      computeBoundingBox();
    }
  }, [loadingState, root]);

  const handlePointerUp = () => {
    setActiveSection(section_id);
    setSelectedCategory([id, instance]);
  };

  const handlePointerOver = (e: any) => {
    e.stopPropagation();
    document.body.style.cursor = 'pointer';
  };

  const handlePointerOut = (e: any) => {
    e.stopPropagation();
    document.body.style.cursor = 'auto';
  };

  return (
    <>
      <group userData-section={section_id}>
        <Cmp {...instance} active={active} ref={ref} />
      </group>

      {hitbox && (
        <group
          onPointerUp={handlePointerUp}
          onPointerOver={isMobile ? undefined : handlePointerOver}
          onPointerOut={isMobile ? undefined : handlePointerOut}
        >
          {hitbox.tooltip && (
            <Bubble
              text={title}
              orientation={
                instance.tooltip || (selectedCategory && selectedCategory[1] === instance && 'above')
              }
              position={hitbox.tooltip}
              size={TOOLTIP_SIZE}
              icon={icon}
              visible={
                (!selectedCategory && activeSection === section_id && instance.tooltip) ||
                (selectedCategory && selectedCategory[1] === instance)
              }
              immediate={
                !isString(previousActiveSection) || (selectedCategory && selectedCategory[1] === instance)
              }
            />
          )}
          <mesh {...omit(hitbox, 'tooltip')}>
            <boxBufferGeometry args={[1, 1, 1]} attach="geometry" />
            <meshBasicMaterial attach="material" alphaMap={alpha} alphaTest={0.5} />
          </mesh>
        </group>
      )}
    </>
  );
};
