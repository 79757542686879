import React, { forwardRef } from 'react';
import { Device } from './Device';
import { WifiWave } from './WifiWave';

export const Router = forwardRef((props: any, r) => {
  const { position } = props;

  return (
    <>
      <Device {...props} ref={r} model="router" />
      <WifiWave position={position} active={props.active} />
    </>
  );
});
