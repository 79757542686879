import React, { forwardRef } from 'react';
import pick from 'lodash/pick';
import { Instance } from '../../../types';
import { useTexture } from '../../../hooks/use-loaded';

export const Tv = forwardRef((props: Instance & { active: any }, r) => {
  const alpha = useTexture('alphamap');

  return (
    <group {...pick(props, 'position', 'rotation', 'quaternion')}>
      <mesh ref={r}>
        <boxBufferGeometry args={[0.06, 0.25, 0.49]} attach="geometry" />
        <meshBasicMaterial attach="material" alphaMap={alpha} alphaTest={0.5} />
      </mesh>
    </group>
  );
});
