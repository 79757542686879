import { Column, Row } from '../grid';
import { Container } from '../container/Container';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useChain, useSpring, animated, config } from 'react-spring';
import LogoSvg from '../../assets/svg/logo.svg';
import { Loader } from '../loader/Loader';
import { responsiveFont } from '../../styles/utils';
import { breakpoints, variables } from '../../styles/variables';

interface IntroProps {
  heading: React.ReactNode;
  cta: React.ReactNode;
  description: React.ReactNode;
  loaded: boolean;
  progress: number;
  onLoadStart(): void;
  onLoadEnd(): void;
}

const Heading = styled.h1`
  ${responsiveFont(56, 160)}
  font-family: Helvetica;
  letter-spacing: -3.33px;
  font-family: ${variables.font.familyHeading};
  font-weight: bold;
  line-height: 1;

  margin-bottom: 50px;

  @media (min-width: ${breakpoints.md}) {
    ${responsiveFont(56, 175)}
  }

  @media (min-width: 1440px) {
    ${responsiveFont(56, 195)}
  }
`;

const Description = styled(animated.div)`
  font-weight: 600;
  line-height: ${30 / 16};
  font-size: 18px;
  color: #fff;
  font-family: ${variables.font.familyHeading};
  letter-spacing: 0.12px;

  opacity: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-right: 60px;
  }

  a {
    text-decoration: none;
    font-weight: 400;
    color: #fff;
    transition: 200ms opacity ease-in-out;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Logo = styled(LogoSvg)`
  transform: scale(0.5);
  transform-origin: bottom left;

  @media (min-width: ${breakpoints.md}) {
    transform: none;
    margin-bottom: 10px;
  }
`;

const CtaWrapper = styled(animated.div)`
  display: flex;

  opacity: 0;

  @media (max-width: ${breakpoints.md}) {
    margin-top: 60px;
    justify-content: flex-end;
  }
`;

const IntroWrapper = styled(animated.div)`
  position: relative;
  color: #fff;
`;

const Loading = styled.div`
  position: relative;
  width: 100%;
`;

const ColContent = styled(Column)`
  display: flex;
  flex-direction: column;

  margin: 0 auto;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  @media (min-width: 1080px) {
    flex-direction: row;
    max-width: 80%;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  width: 100%;
`;

const IntroContainer = styled(Container)`
  margin-bottom: 60px;
  padding-left: 50px;
  padding-right: 50px;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 130px;
    padding-right: 130px;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 140px;
  }

  @media (min-width: 1440px) {
    padding-left: 70px;
    padding-right: 70px;
  }
`;

export function Intro({ heading, description, cta, loaded, progress, onLoadStart, onLoadEnd }: IntroProps) {
  const [introDone, setIntroDone] = useState(false);

  const [introProps, setIntroProps] = useSpring(() => ({
    opacity: 0,
    config: { mass: 0.4, friction: 70 },
  }));

  const [descriptionProps, setDescriptionProps] = useSpring(() => ({
    opacity: 0,
    config: { mass: 0.5, friction: 50 },
  }));

  const [ctaProps, setCtaProps] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
    config: { mass: 0.5, friction: 50 },
  }));

  const onLoaderComplete = () => {
    onLoadEnd();

    // fade in text blocks and cta button
    setDescriptionProps({ opacity: 1 });
    setCtaProps({
      opacity: 1,
      pointerEvents: 'auto',
      delay: 450,
    });
  };

  React.useEffect(() => {
    setIntroProps({
      opacity: 1,
    });
    // onRest just not reliable enough
    setTimeout(() => setIntroDone(true), 300);
  }, []);

  return (
    <IntroContainer>
      <IntroWrapper style={introProps}>
        <Logo />
        <Heading>{heading}</Heading>
      </IntroWrapper>

      <Loading>
        <LoadingWrapper>
          <Loader
            isPreloadCompleted={loaded}
            onProgressVisible={onLoadStart}
            percentage={progress}
            animateInLoader={introDone}
            onAnimationOutLoader={onLoaderComplete}
          />
        </LoadingWrapper>
      </Loading>

      <Row align="center">
        <ColContent>
          <Description style={{ ...descriptionProps }}>{description}</Description>
          <CtaWrapper style={{ ...ctaProps }}>{cta}</CtaWrapper>
        </ColContent>
      </Row>
    </IntroContainer>
  );
}
