import React, { useMemo, useContext } from 'react';
import { DataContext } from '../context/DataContext';
import { Category } from '../types';

export function useSectionCategories(section: string) {
  const {
    data: { categories },
  } = useContext(DataContext);

  const cats = useMemo<Category[]>(() => categories.filter(x => x.section_id === section), [categories]);

  return cats;
}
