import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useChain, useSpring, animated, config } from 'react-spring';

import { breakpoints, variables } from '../../styles/variables';

import Facebook from '../../assets/svg/facebook.svg';
import Twitter from '../../assets/svg/twitter.svg';

export interface ShareProps {
  visible: boolean;
  night?: boolean;
}

const ShareRoot = styled(animated.div)`
  position: fixed;
  z-index: 2;
  opacity: 0;

  right: 25px;
  bottom: 25px;

  display: flex;
  align-items: center;

  transform: translateY(150%);

  @media (min-width: ${breakpoints.lg}) {
    right: 45px;
    bottom: 45px;
  }
`;

const Title = styled.span<{ night: boolean }>`
  display: none;
  padding-right: 15px;

  font-weight: 500;
  font-size: 15px;
  color: ${props => (props.night ? '#fff' : variables.colors.font)} ;
  letter-spacing: 0;

  transition: color 200ms ease-in-out;
  @media (min-width: ${breakpoints.lg}) {
    display: inline-flex;
  }
`;

const List = styled.ul`
  display: flex;
`;

const Item = styled.li`
  margin-left: 5px;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: 10px;
  }
`;

const Fill = styled.path`
  color: ${variables.colors.font};

  transition: color 200ms ease-in-out, fill 200ms ease-in-out;
`;


const Button = styled.button<{ night: boolean, type: string }>`
  display: inline-flex;
  align-items: center;

  height: 100%;
  padding: 5px;

  background: transparent;

  &:focus {
    outline: none;
  }

  svg {
    width: 26px;
    height: 26px;
  }

  cursor: pointer;
  color: ${props => (props.night ? '#fff' : variables.colors.font)};

  &:hover {
    color: ${props => (props.type === 'facebook' ? '#3b5998' : '#1DA1F2')};

    ${Fill} {
      color: #fff;
    }
  }

  transition: color 200ms ease-in-out, fill 200ms ease-in-out;

  @media (min-width: ${breakpoints.lg}) {
    svg {
      width: 36px;
      height: 36px;
    }
  }
`;



export function Share({ night, visible }: ZoomProps) {
  const [shareProps, setShareProps] = useSpring(() => ({
    transform: 'translateY(150%)',
    opacity: 0,
    config: { mass: 0.5, friction: 50 },
  }));

  const onClick = (platform: string) => {
    const url = window.location.href;

    const encodedUrl = encodeURIComponent(url);

    const urls: any = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodedUrl} - Nova Snjallheimilið`,
    };

    window.open(urls[platform], '_blank', 'width=600,height=300');
  };

  useEffect(() => {
    if (visible) {
      setShareProps({
        opacity: 1,
        transform: 'translateY(0%)',
     });
    }
  }, [visible]);

  return (
    <ShareRoot style={{ ...shareProps }}>
      <Title night={night}>Deila</Title>
      <List>
        <Item>
          <Button
            title="share on Facebook"
            onClick={() => onClick('facebook')}
            night={night}
            type="facebook"
          >
            <svg viewBox="0 0 36 36" height="36px" width="36px">
              <g fill="none" fillRule="evenodd">
                <path
                  fill="currentColor"
                  d="M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18z"
                ></path>
                <Fill
                  fill="currentColor"
                  d="M19.276 27.984V18.19h2.703l.359-3.375h-3.062l.005-1.69c0-.88.083-1.35 1.348-1.35h1.69V8.4h-2.704c-3.248 0-4.39 1.637-4.39 4.39v2.026H13.2v3.375h2.024v9.793h4.052z"
                ></Fill>
              </g>
            </svg>
          </Button>
        </Item>
        <Item>
          <Button
            title="share on Twitter"
            onClick={() => onClick('twitter')}
            night={night}
            type="twitter"
          >
            <svg viewBox="0 0 36 36" height="36px" width="36px">
              <g fill="none" fillRule="evenodd">
                <path
                  fill="currentColor"
                  d="M0 18C0 8.059 8.059 0 18 0s18 8.059 18 18-8.059 18-18 18S0 27.941 0 18z"
                ></path>
                <Fill
                  fill="currentColor"
                  d="M17.46 14.63l.039.623-.63-.076c-2.291-.292-4.293-1.284-5.993-2.949l-.831-.826-.214.61c-.453 1.36-.164 2.797.78 3.763.504.533.39.61-.478.292-.302-.102-.566-.178-.592-.14-.088.09.214 1.246.454 1.703.327.636.994 1.259 1.725 1.627l.617.293-.73.013c-.706 0-.731.012-.655.28.251.825 1.246 1.702 2.354 2.084l.78.267-.68.406a7.089 7.089 0 01-3.374.941c-.566.013-1.032.064-1.032.102 0 .127 1.536.839 2.43 1.118 2.682.827 5.867.47 8.26-.94 1.7-1.004 3.399-3 4.192-4.932.428-1.03.856-2.911.856-3.813 0-.585.038-.661.743-1.36.416-.407.806-.852.881-.98.126-.24.114-.24-.528-.025-1.07.382-1.222.33-.693-.241.39-.407.856-1.144.856-1.36 0-.038-.189.025-.403.14-.226.127-.73.317-1.108.432l-.68.216-.616-.42c-.34-.229-.819-.483-1.07-.559-.643-.178-1.625-.153-2.204.05-1.574.573-2.568 2.047-2.455 3.662z"
                ></Fill>
              </g>
            </svg>
          </Button>
        </Item>
      </List>
    </ShareRoot>
  );
};
