import React from 'react';
import { Category, Section, Product } from '../../types';
import { Modal } from '../modal/Modal';
import { Tabs } from '../tabs/Tabs';
import { ModalHeader } from '../modal/ModalHeader';
import { Headline } from '../headline/Headline';
import styled from 'styled-components';
import { responsiveFont } from '../../styles/utils';
import { variables, breakpoints } from '../../styles/variables';

import { ProductItem } from '../product-item/ProductItem';
import { Carousel } from '../carousel/Carousel';

export type CategoryDetails = Category & {
  section: Section;
  products: Product[];
};

interface CategoryModalProps {
  category: CategoryDetails | null;
  onClose?(): void;
}

const Description = styled.p`
  ${responsiveFont(14, 18)};
  line-height: ${30 / 18};

  margin-top: 20px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 30px;
  }
`;

const TabsContainer = styled.div`
  margin-top: 50px;

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 80px;
  }
`;

function toProductItem(item: Product): JSX.Element {
  return (
    <ProductItem
      key={item.id}
      kicker={item.vendor}
      heading={item.title}
      image={item.photo}
      price={item.price}
      link={item.link}
    />
  );
}

export const CategoryModal: React.FC<CategoryModalProps> = ({ category, onClose }) => {
  const products = (category && category.products.filter(i => i.type === 'product')) || [];
  const bundles = (category && category.products.filter(i => i.type === 'bundle')) || [];

  const items = [
    products.length > 0
      ? {
          title: 'Vörur',
          children: <Carousel>{products.map(toProductItem)}</Carousel>,
        }
      : null,
    bundles.length > 0
      ? {
          title: 'Pakkar',
          children: <Carousel isConstrained>{bundles.map(toProductItem)}</Carousel>,
        }
      : null,
  ].filter(Boolean);

  return (
    <Modal visible={category !== null} onClose={onClose}>
      {category && (
        <>
          <ModalHeader title={category.section.title} icon={category.section.icon} onClose={onClose} />

          <Headline text={category.title} />

          <Description>{category.description}</Description>

          <TabsContainer>
            <Tabs items={items} />
          </TabsContainer>
        </>
      )}
    </Modal>
  );
};
