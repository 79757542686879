import React from 'react';
import { useModel } from '../../../hooks/use-loaded';
import { useNightTextures } from '../../../hooks/use-night-textures';

export const ChristmasGround = () => {
  const gltf = useModel('exterior');

  useNightTextures(gltf.scene, [
    'floor_night_texture',
    'houses_night_texture',
    'cars_night_texture',
    
    'Bulbs_night_texture',
    'SnowMan_night_texture',
    'Snow_night_texture',
    'Trees_night_texture',

  ], ['Floor']);

  return <primitive object={gltf.scene} scale={[0.0254, 0.0254, 0.0254]} />;
};
