import React, { useContext } from 'react';
import { DeviceRenderer } from './objects/DeviceRenderer';
import { Cat } from './objects/Cat';
import { UIContext } from '../../context/UIContext';

export const HomeControl = () => {
  const { activeSection } = useContext(UIContext);

  return (
    <>
      <Cat active={activeSection === 'home-control'} position={[0,0, 0]} />
      <DeviceRenderer section="home-control" />
    </>
  );
};
