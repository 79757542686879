import React, { forwardRef } from 'react';
import { Device } from './Device';
import { WifiWave } from './WifiWave';

export const Extender = forwardRef((props: any, ref) => {
  return (
    <>
      <Device {...props} ref={ref} model="extender" />
      <WifiWave position={props.position} active={props.active} />
    </>
  );
});
