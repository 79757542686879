import React from 'react';
import { useSpring, a } from 'react-spring/three';
import { Sky } from './objects/Sky';
import { useDayNight } from '../../hooks/use-day-night';

export const Lights: React.FC = () => {
  const nightLevel = useDayNight();

  const props = useSpring({
    x: nightLevel,
    config: { tension: 140, friction: 40 },
  });

  return (
    <>
      <a.directionalLight
        position={[0, 1, 1]}
        intensity={props.x.interpolate({ range: [0, 1], output: [0.5, 0.5] })}
      />

      <a.directionalLight
        position={[-1, -1, -1]}
        intensity={props.x.interpolate({ range: [0, 1], output: [0.5, 0.5] })}
      />

      <a.directionalLight
        position={[1, -1, -1]}
        intensity={props.x.interpolate({ range: [0, 1], output: [0.5, 0.5] })}
      />

      <a.ambientLight intensity={props.x.interpolate({ range: [0, 1], output: [0.5, 0.5] })} />

      <Sky x={props.x} />
    </>
  );
};
