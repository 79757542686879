import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { breakpoints, variables } from '../../styles/variables';
import { useSpring, animated } from 'react-spring';
import { useResize } from '../../hooks/useResize';

import { MobileButton } from './MobileButton';
import { Section } from '../../types';

const MenuRoot = styled.div`

`;

interface SectionProps {
  selected?: boolean;
}

const Button = styled.button`
  display: flex;
  align-items: center;

  background: transparent;
  color: #fff;

  font-weight: 500;
  font-size: 15px;
  color: #fff;
  letter-spacing: 0;

  cursor: pointer;

  outline: none;
`;

const Item = styled.li`
  height: 60px;
  line-height: 60px;
  margin-left: 10px;
`;

const Icon = styled.div`
  display: inline-flex;
  width: 45px;
`;

const Text = styled.div`
  display: inline-flex;
`;

const ListWrapper = styled(animated.div)`
  align-items: center;

  position: relative;

  margin-left: auto;

  display: flex;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;

  padding: 60px 20px 20px 30px;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  background-color: ${variables.colors.red};

  will-change: opacity, transform;


  @media (min-width: ${breakpoints.lg}}) {
    display: none;
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

interface MobileMenuProps {
  sections: Section[];
  activeSection: string | null;
  onSectionSelect(section: string): void;
  onMenuToggle(): void;
}

export const MobileMenu = React.memo(({ sections, activeSection, onSectionSelect, onMenuToggle }: MobileMenuProps) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isMobile, isDesktop] = useResize();

  const [listProps, setList] = useSpring(() => ({
    opacity: 0,
    y: -100 ,
    pointerEvents: 'none',
    config: { mass: 0.6, friction: 18 },
  }));

  const onNavClick = () => {
    const newState = !isMenuOpen;

    setList({
      opacity: newState ? 1 : 0,
      y: newState ? -5 : -100,
      pointerEvents: newState ? 'all' : 'none',
    });

    onMenuToggle();
    setMenuOpen(newState);
  }

  const onMenuClick = (id) => {
    onSectionSelect(id);
    closeMenu();
  }

  const closeMenu = () => {
    setMenuOpen(false);

    setList({
      opacity: 0,
      y: -100,
      pointerEvents: 'none',
    });
  }

  useEffect(() => {
    if (isDesktop) {
      closeMenu();
    }
  }, [isDesktop]);

  const activeNav = sections
    .filter(section => section.id === activeSection);

  return (
    <>
      <MenuRoot>
        <MobileButton isActive={isMenuOpen} onClick={onNavClick}>
          {activeNav[0] && activeNav[0].icon}
        </MobileButton>
        <ListWrapper
          style={{
            ...listProps,
            transform: listProps.y.interpolate((y) => `translate3d(0, ${y}%, 0)`),
          }}
        >
          <List role="menubar" aria-label="main-navigation">
            {sections.map(c => (
              <Item key={c.id}>
                <Button
                  active={activeSection === c.id}
                  onClick={() => onMenuClick(c.id)}
                >
                  <Icon>{c.icon}</Icon>
                  <Text>{c.title}</Text>
                </Button>
              </Item>
            ))}
          </List>
        </ListWrapper>
      </MenuRoot>
    </>
  );
});
