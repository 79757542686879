import { useEffect, useState } from 'react';

export function useKiosk() {
  const [isKiosk, set] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    set(/kiosk/gi.test(window.location.search));
  }, []);

  return isKiosk;
}
