import React, { useEffect, useContext } from 'react';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';
import { useSpring, animated as a } from 'react-spring';
import { breakpoints, variables } from '../../styles/variables';

import { UIContext } from '../../context/UIContext';
import { usePinch } from 'react-use-gesture';

export interface ZoomProps {
  high: number;
  low: number;
  current: number;
  visible: boolean;

  onChange(z: number): void;
}

interface ButtonProps {
  active: boolean;
}

const ZoomButton = styled.button<ButtonProps>`
  background: white;
  padding: 0;
  width: 36px;
  height: 34px;
  outline: none;
  font-size: 24px;

  border-bottom: 1px solid ${rgba('#D8D8D8', 0.25)};

  color: ${variables.colors.font};

  cursor: pointer;

  transition: background 100ms ease-out;

  &:hover {
    color: ${variables.colors.red};
  }


  ${props =>
    props.active &&
    css`
      background: #f5f5f5;
      color: #bdbdbd;
      cursor: default;

      &:hover {
        color: #bdbdbd;
      }
    `}
`;

const ZoomContainer = styled(a.div)`
  position: absolute;

  bottom: 30px;
  left: 30px;

  z-index: 1;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  background: white;
  border-radius: 18px;
  box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.2);

  @media (min-width: ${breakpoints.lg}) {
    bottom: 50px;
    left: 50px;
  }

  ${ZoomButton}:nth-child (2) {
    transform-origin: center center;
    transform: rotate(180deg);
  }
`;

const DragtipBox = styled(a.div)<Omit<TooltipProps, "title">>`
  position: fixed;
  z-index: 3;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Bubble = styled.button`
  position: relative;

  padding: 15px 20px 10px;

  color: #000;
  font-weight: 600;
  font-size: 14px;
  font-family: ${variables.font.familyHeading};

  border-radius: 38px;
  z-index: 1;
  background: #fff;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));

  outline: none;
  cursor: pointer;

  @media (min-width: ${breakpoints.lg}) {
    padding: 30px 40px 25px;

    font-weight: 700;
    font-size: 17px;
  }
`;

export const Zoom = ({ current, high, low, onChange, visible }: ZoomProps) => {
  const { y } = useSpring({ y: Number(visible) });
  const { dragTip, setDragTip, isMobile, setSelectedCategory, selectedCategory } = useContext(UIContext);

  const changeZoom = (value) => {
    if (selectedCategory !== null) {
      setSelectedCategory(null);
    } else {
      onChange(value);
    }
  }

  const bind = usePinch(
    ({ da: [d] }) => {
      if (current === high && d > -50) {
        onChange(low);
      } else if (current === low && d > 50) {
        onChange(high);
      }
    },
    { domTarget: typeof window !== 'undefined' ? window : undefined }
  );

  useEffect(bind as any, []);

  const interp = () => ({
    transform: y
      .interpolate({ range: [0, 1], output: [-50, 0] })
      .interpolate(v => `translate3d(0, ${v}, 0)`),
    opacity: y
  });

  const [dragTipProps, setDragTipProps] = useSpring(() => ({
    opacity: 0,
    pointerEvents: 'none',
    config: { mass: 0.2, friction: 40 }
  }));

  const openDragTip = () => {
    if (dragTip) {
      setDragTipProps({
        pointerEvents: 'auto',
        opacity: 1,
        delay: 2000
      });
    }
  };

  const closeDragTip = () => {
    setDragTipProps({
      opacity: 0,
      pointerEvents: 'none',
      delay: 500
    });
    setDragTip(false);
  };

  useEffect(() => {
    if (isMobile && current > 1 && dragTip) {
      openDragTip();
    }
  }, [current]);

  useEffect(() => {
    if (isMobile && current > 1 && !dragTip) {
      closeDragTip();
    }
  }, [dragTip]);

  return (
    <>
      <ZoomContainer style={interp()}>
        <ZoomButton active={high === current || selectedCategory !== null} onClick={() => changeZoom(high)}>
          +
        </ZoomButton>
        <ZoomButton active={low === current && selectedCategory === null} onClick={() => changeZoom(low)}>
          −
        </ZoomButton>
      </ZoomContainer>

      <DragtipBox style={dragTipProps}>
        <Bubble onClick={closeDragTip}>Dragðu til að skoða húsið</Bubble>
      </DragtipBox>
    </>
  );
};
