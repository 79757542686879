import React, { useState, useRef, useEffect } from 'react';
import { useFrame, useThree } from 'react-three-fiber';
import { Wifi } from '../shaders/Wifi';
import { DoubleSide, ShaderMaterial } from 'three';
import { a } from 'react-spring/three';

export const WifiWave = (props: any) => {
  const { clock } = useThree();

  const [start] = useState(() => clock.elapsedTime);

  const ref = useRef<ShaderMaterial>();

  useFrame(({ clock: c }) => {
    ref.current!.uniforms.time.value = c.elapsedTime - start;
  });

  const [wifi] = useState(() => Wifi());

  return (
    <mesh userData-skip={true} rotation={[Math.PI, 0, 0]} position={props.position}>
      <planeBufferGeometry args={[1.5, 1.5]} attach="geometry" />
      <a.shaderMaterial
        attach="material"
        args={[wifi]}
        transparent
        ref={ref}
        side={DoubleSide}
        depthTest={false}
        uniforms-opacity-value={props.active}
      />
    </mesh>
  );
};
