import React, { useRef, useState, useEffect, useMemo } from 'react';
import { CircleBufferGeometry, Color, Group, Mesh, RepeatWrapping, NormalBlending, AdditiveBlending } from 'three';
import { useSpring, animated as a } from 'react-spring/three';
import { useTexture } from '../../../hooks/use-loaded';
import { AlwaysDepth, LessDepth } from 'three';
import { useResource } from 'react-three-fiber';
import { addV } from 'react-use-gesture';
import { config } from 'react-spring';

const PINK = new Color(0xe42e5a);

export const Bubble = ({ size = 0.14, position, icon, visible, orientation, immediate }: any) => {
  const circle = useRef<CircleBufferGeometry>();
  const [rootRef, root] = useResource<Group>();

  const [hover, setHover] = useState(false);

  const rootProps = useSpring({
    to: visible
      ? { scale: [size, size, size], rotation: [0, 0, 0] }
      : { rotation: [0, Math.PI / 2, 0], scale: [0, 0, 0] },
    delay: !visible || immediate ? 0 : 1000,
    config: config.slow,
  });

  const hoverProps = useSpring({
    scale: [hover ? 1.2 : 1, hover ? 1.2 : 1, hover ? 1.2 : 1],
  });

  const texture = useTexture(icon);

  useEffect(() => {
    if (!root) {
      return;
    }

    root.traverse(obj => {
      if (obj instanceof Mesh) {
        obj.layers.set(2);
      }
    });
  }, [root]);

  const diamondPosition = useMemo(() => {
    if (orientation === 'above') {
      return [0, -0.79, 0];
    } else if (orientation === 'left') {
      return [0.79, 0, 0];
    }

    return [0, 0.79, 0];
  }, [orientation]);

  useEffect(() => {
    texture.wrapS = RepeatWrapping;
    texture.wrapT = RepeatWrapping;
    texture.anisotropy = 0;

    texture.repeat.set(1, 1);
    texture.needsUpdate = true;
  }, []);

  const handlePointerOver = () => setHover(true);
  const handlePointerOut = () => setHover(false);

  const rootPosition = useMemo(() => {
    const random = Math.random() * 0.001;
    return addV(open ? addV(position, [0, 0, 0.003]) : position, [0, 0, random]);
  }, [position, open]);

  const iconSize = icon === 'icon_tv' ? 1 : 2;
  const iconBlending = icon === 'icon_tv' ? NormalBlending : AdditiveBlending;
  const backgroundColor = icon !== 'icon_tv' ? PINK : 'white';

  return (
    <a.group
      ref={rootRef}
      position={rootPosition}
      {...rootProps}
    >
      <a.group {...hoverProps}>
        <mesh position={[0, 0, 0.025]}>
          <planeGeometry attach="geometry" args={[iconSize, iconSize]} />
          <meshBasicMaterial
            color="white"
            attach="material"
            map={texture}
            transparent
            blending={iconBlending}
            depthFunc={open ? AlwaysDepth : LessDepth}
          />
        </mesh>
        <mesh>
          <circleBufferGeometry attach="geometry" args={[0.8, 48]} ref={circle} />
          <meshBasicMaterial
            color={backgroundColor}
            attach="material"
            depthFunc={open ? AlwaysDepth : LessDepth}
          />
        </mesh>

        <mesh position={diamondPosition} rotation-z={Math.PI / 4}>
          <planeBufferGeometry attach="geometry" args={[0.2, 0.2]} />
          <meshBasicMaterial
            color={backgroundColor}
            attach="material"
            depthFunc={open ? AlwaysDepth : LessDepth}
          />
        </mesh>
      </a.group>
    </a.group>
  );
};
